import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    notification: {
        width: 'auto',
    },
    button: {
        marginLeft: '$spaceS',
        marginTop: '$spaceS * -1',
        marginRight: '$spaceS * -1',
    },
});
