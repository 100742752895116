import { UTC } from './UTC';
const getDateProps = (value = '') => value
    .split('/')
    .filter((part) => /^\d+$/.test(part))
    .map((part) => (part ? parseInt(part, 10) : undefined));
const createDate = (year, month, day) => UTC(new Date(year, month - 1, day, 12, 0, 0));
export const isValidDate = (value = '', { min, max } = {}) => {
    try {
        const [year, month, day] = getDateProps(value);
        if (!year || !month || !day)
            return false;
        const stringDate = `${year}-${`${month}`.padStart(2, '0')}-${`${day}`.padStart(2, '0')}T12:00:00.000Z`;
        const date = new Date(stringDate);
        const isValid = date.toISOString() === stringDate;
        let inRange = true;
        if (min || max) {
            const minTs = min ? UTC(new Date(createDate(...getDateProps(min)))) : undefined;
            const maxTs = max ? UTC(new Date(createDate(...getDateProps(max)))) : undefined;
            const dateTs = date.getTime();
            const inMinRange = minTs ? dateTs >= minTs : true;
            const inMaxRange = maxTs ? dateTs <= maxTs : true;
            inRange = inMinRange && inMaxRange;
        }
        return isValid && inRange;
    }
    catch (err) {
        return false;
    }
};
