var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useImperativeHandle, useRef, useState } from 'react';
import { Platform, TextInput } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
import { getInputErrors, stylesheetFont, testID } from '../../../helpers';
import { styles, useBanProps, useSupportedProps, useBanStylerProps, useStyler, useDevice } from '../../../hooks';
import { Theme } from '../../../theming';
import { TEXT_STYLE } from '../Text';
import { parseValue } from './helpers';
import { BANNED_PROPS, INPUT_TYPES, KEYBOARD_TYPES, TEXT_CONTENT_TYPES, TYPE_NUMBER } from './Input.definition';
import { style } from './Input.style';
const Input = React.forwardRef((_a, ref) => {
    var { disabled, id, keyboardType, multiline, placeholder, placeholderColor = StyleSheet.value('$colorGrayscaleL'), role, type = INPUT_TYPES.text, onChange, onError } = _a, others = __rest(_a, ["disabled", "id", "keyboardType", "multiline", "placeholder", "placeholderColor", "role", "type", "onChange", "onError"]);
    const { layoutXL, spaceM } = Theme.get();
    const MULTILINE_MIN_HEIGHT = layoutXL + spaceM;
    const [contentHeight, setContentHeight] = useState(MULTILINE_MIN_HEIGHT);
    const inputRef = useRef(ref);
    const isNumber = type === TYPE_NUMBER;
    useImperativeHandle(ref, () => inputRef.current);
    const handleChangeText = (next = '') => {
        if (isNumber && isNaN(next))
            return;
        const value = parseValue(next, type, others);
        onError && onError(getInputErrors(Object.assign(Object.assign({}, others), { type, value })));
        onChange && onChange(isNumber ? value : value || '');
    };
    const handleContentSizeChange = ({ nativeEvent }) => {
        var _a;
        if (((_a = nativeEvent === null || nativeEvent === void 0 ? void 0 : nativeEvent.contentSize) === null || _a === void 0 ? void 0 : _a.height) > MULTILINE_MIN_HEIGHT) {
            setContentHeight(nativeEvent.contentSize.height);
        }
    };
    return (React.createElement(TextInput, Object.assign({}, useBanProps(useBanStylerProps(others), [...BANNED_PROPS]), testID(others.testID, 'input'), { style: styles(stylesheetFont({ nameSpace: '$fontInput' }).font, style.input, disabled && style.disabled, multiline && style.multiline, multiline && Platform.OS === 'web' && { minHeight: contentHeight }, ...useStyler(useSupportedProps(others, Object.values(TEXT_STYLE)), Input.displayName, useDevice()).style, others.style), accessibilityRole: Platform.OS === 'web' ? role : undefined, allowFontScaling: false, autoCapitalize: "none", autoCorrect: true, editable: !disabled, keyboardType: keyboardType || KEYBOARD_TYPES[type] || KEYBOARD_TYPES.text, multiline: multiline, nativeID: id, placeholder: placeholder, placeholderTextColor: placeholderColor, ref: inputRef, secureTextEntry: type === 'password', textContentType: TEXT_CONTENT_TYPES[type], onChangeText: handleChangeText, onContentSizeChange: multiline && Platform.OS === 'web' ? handleContentSizeChange : undefined })));
});
Input.displayName = 'Input';
export { Input };
