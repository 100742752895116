import StyleSheet from 'react-native-extended-stylesheet';
const spinnerSize = 72;
export const style = StyleSheet.create({
    icon: {
        minWidth: '$fontBrandSize1',
        width: '$fontBrandSize1',
        minHeight: '$fontBrandSize1',
        height: '$fontBrandSize1',
        fontSize: '$fontBrandSize1',
    },
    iconContainer: {
        width: spinnerSize,
        height: spinnerSize,
        position: 'absolute',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    image: {
        width: spinnerSize,
        height: spinnerSize,
        alignSelf: 'center',
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
