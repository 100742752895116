var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React from 'react';
import { COLOR, FLEX_DIRECTION, SIZE } from '../../../hooks';
import { Touchable, View } from '../../primitives';
import { style } from './Progress.style';
const Progress = (_a) => {
    var { active = 0, activeColor = COLOR.CONTENT, inactiveColor = COLOR.GRAYSCALE_M, length = 1, onPress } = _a, others = __rest(_a, ["active", "activeColor", "inactiveColor", "length", "onPress"]);
    return (React.createElement(View, Object.assign({}, others, { flexDirection: FLEX_DIRECTION.ROW, wide: true }), Array.from(Array(length).keys()).map((step, index) => (React.createElement(Touchable, { backgroundColor: index <= active ? activeColor : inactiveColor, flex: SIZE.XS, hitSlop: { top: 16, bottom: 16 }, key: step, marginRight: index < length - 1 ? SIZE.XS : undefined, style: style.item, onPress: onPress ? () => onPress(index) : undefined })))));
};
Progress.displayName = 'Progress';
export { Progress };
