var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React from 'react';
import { Platform, StatusBar } from 'react-native';
import { ButtonIcon } from '../../atoms';
import { ALIGN, COLOR, FLEX_DIRECTION, POSITION, SIZE, styles } from '../../../hooks';
import { SafeAreaView, Text, View, VIEW_ROLES } from '../../primitives';
import { style } from './Header.style';
export const Header = (_a) => {
    var { children, color = COLOR.BASE, container: Container = React.Fragment, fullWidth = true, logo, notification, onBack, onClose, onMenu, title } = _a, others = __rest(_a, ["children", "color", "container", "fullWidth", "logo", "notification", "onBack", "onClose", "onMenu", "title"]);
    const colorContent = color === COLOR.CONTENT ? COLOR.BASE : undefined;
    return (React.createElement(View, Object.assign({}, others, { backgroundColor: color, role: VIEW_ROLES.header, wide: true }),
        React.createElement(Container, null,
            React.createElement(SafeAreaView, { style: Platform.OS === 'android' ? { paddingTop: StatusBar.currentHeight } : undefined },
                React.createElement(View, { alignItems: ALIGN.CENTER, alignSelf: ALIGN.CENTER, flexDirection: FLEX_DIRECTION.ROW, paddingHorizontal: SIZE.S, style: styles(style.header, fullWidth && style.fullWidth) },
                    onBack && React.createElement(ButtonIcon, { alignSelf: ALIGN.CENTER, color: colorContent, name: "arrow-left", onPress: onBack }),
                    logo && (React.createElement(View, { position: POSITION.ABSOLUTE, style: style.logo }, logo)),
                    title || onClose || onMenu ? (React.createElement(React.Fragment, null,
                        title && (React.createElement(Text, { action: true, align: ALIGN.CENTER, color: colorContent, position: POSITION.ABSOLUTE, style: style.title, upperCase: true }, title)),
                        React.createElement(View, { alignItems: ALIGN.CENTER, flexDirection: FLEX_DIRECTION.ROW, style: style.actions },
                            children,
                            onMenu && (React.createElement(ButtonIcon, { alignSelf: ALIGN.CENTER, color: colorContent, name: "menu", nativeID: "menu-button", notification: notification, onPress: onMenu, testID: "menu-button" })),
                            onClose && React.createElement(ButtonIcon, { color: colorContent, name: "close", onPress: onClose })))) : (children))))));
};
Header.displayName = 'Header';
