var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { styles, useDevice } from '../../../hooks';
import { View } from '../../primitives';
import { style } from './Box.style';
import { determineSize } from './helpers';
const Box = React.forwardRef((_a, ref) => {
    var { align, children, size } = _a, others = __rest(_a, ["align", "children", "size"]);
    const { screen } = useDevice();
    return (React.createElement(View, Object.assign({}, others, { ref: ref, style: styles(!screen.S || (size && Object.keys(size).includes('S'))
            ? styles(style[determineSize(size, screen)], style[align])
            : undefined, others.style), wide: true }), children));
});
Box.displayName = 'Box';
export { Box };
