import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    button: {
        borderStyle: '$borderStyle',
        borderWidth: '$borderSize',
        padding: '$spaceM',
        alignSelf: 'stretch',
    },
    buttonInput: {
        marginTop: '$fontDetailPaddingTop1',
    },
    card: {
        borderStyle: '$borderStyle',
        borderWidth: '$borderSize',
        height: '$layoutXXL',
        paddingHorizontal: '$spaceS',
    },
    cardSmall: {
        height: '$inputSizeSmall',
    },
    pill: {
        borderStyle: '$borderStyle',
        borderWidth: '$borderSize',
        borderRadius: '$inputSize',
        height: '$inputSize',
        paddingHorizontal: '$spaceS',
    },
});
