import StyleSheet from 'react-native-extended-stylesheet';
import { DefaultTheme } from './theme.default';
const THEME_NAME = '$theme';
const SHORTCUT = '$';
const getStyleSheet = (key) => {
    try {
        return StyleSheet.value(key);
    }
    catch (error) {
        return undefined;
    }
};
export const Theme = {
    get: (key) => {
        let value;
        if (key) {
            value = getStyleSheet(key.startsWith(SHORTCUT) ? key : `${SHORTCUT}${key}`);
        }
        else {
            value = {};
            Object.keys(DefaultTheme).forEach((k) => (value[k.replace(SHORTCUT, '')] = getStyleSheet(k)));
        }
        return value;
    },
    set: (theme = {}) => {
        StyleSheet.build(Object.assign(Object.assign(Object.assign({}, DefaultTheme), theme), { $theme: theme[THEME_NAME] || DefaultTheme[THEME_NAME] }));
    },
};
