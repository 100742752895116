var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React from 'react';
import { ALIGN, COLOR, DISPLAY, FLEX_DIRECTION, POINTER, POSITION, SIZE, styles } from '../../../hooks';
import { Icon, Motion, MOTION_TYPES, Text, TEXT_ROLES, View, VIEW_ROLES } from '../../primitives';
import { style as buildStyle } from './Step.style';
const Step = (_a) => {
    var { active, checked, first, forceActive, horizontal = true, last, text } = _a, others = __rest(_a, ["active", "checked", "first", "forceActive", "horizontal", "last", "text"]);
    const style = buildStyle(horizontal);
    return (React.createElement(View, Object.assign({}, others, { alignItems: ALIGN.CENTER, flexDirection: horizontal ? FLEX_DIRECTION.COLUMN : FLEX_DIRECTION.ROW, role: VIEW_ROLES.li, position: POSITION.RELATIVE, style: styles(style.step, horizontal ? style.horizontal : style.vertical, others.style), wide: horizontal }),
        React.createElement(View, { style: style.track, position: POSITION.RELATIVE, justifyContent: ALIGN.CENTER, wide: horizontal },
            React.createElement(View, { borderColor: active ? COLOR.GRAYSCALE_L : COLOR.GRAYSCALE_S, display: DISPLAY.BLOCK, layer: SIZE.XS, position: POSITION.RELATIVE, style: style.circle },
                React.createElement(Motion, { pointer: POINTER.NONE, position: POSITION.ABSOLUTE, style: style.icon, type: checked ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE, value: { opacity: checked ? 1 : 0, scale: checked ? 1 : 0 } },
                    React.createElement(Icon, { color: !(active || checked) ? COLOR.GRAYSCALE_L : undefined, name: "tick" }))),
            React.createElement(View, { borderColor: active ? COLOR.GRAYSCALE_L : COLOR.GRAYSCALE_S, display: first ? DISPLAY.NONE : undefined, position: first ? POSITION.RELATIVE : POSITION.ABSOLUTE, style: styles(style.line, style.start) }),
            React.createElement(View, { borderColor: checked || forceActive ? COLOR.GRAYSCALE_L : COLOR.GRAYSCALE_S, display: last ? DISPLAY.NONE : undefined, position: last ? POSITION.RELATIVE : POSITION.ABSOLUTE, style: styles(style.line, style.end) })),
        React.createElement(Text, { align: horizontal ? ALIGN.CENTER : undefined, role: TEXT_ROLES.strong, color: !(active || checked) ? COLOR.GRAYSCALE_S : undefined, detail: horizontal, display: DISPLAY.BLOCK, marginTop: horizontal ? SIZE.S : undefined, marginLeft: !horizontal ? SIZE.M : undefined, upperCase: horizontal }, text)));
};
Step.displayName = 'Step';
export { Step };
