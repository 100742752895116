import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    day: {
        borderRadius: '$spaceXL / 2',
        overflow: Platform.OS === 'android' ? 'hidden' : undefined,
        width: '$spaceXL',
        height: '$spaceXL',
    },
    focus: undefined,
    today: {
        borderWidth: '$borderSize',
        borderColor: '$colorContent',
    },
    todayDisabled: {
        borderWidth: '$borderSize',
        borderColor: '$colorGrayscaleM',
    },
});
