var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import { Animated, Pressable } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
import { testID } from '../../../helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler } from '../../../hooks';
import { BANNED_PROPS, SWITCH_VARIANT } from './Switch.definition';
import { style } from './Switch.style';
const Switch = (_a) => {
    var { checked, disabled, variant = SWITCH_VARIANT.CHECKBOX, onChange } = _a, others = __rest(_a, ["checked", "disabled", "variant", "onChange"]);
    const bgColorAnimatedValue = useRef(new Animated.Value(disabled || !checked ? 0 : 100)).current;
    const borderColorAnimatedValue = useRef(new Animated.Value(disabled ? -100 : checked ? 100 : 0)).current;
    useEffect(() => {
        const animation = Animated.parallel([
            Animated.timing(bgColorAnimatedValue, {
                toValue: disabled || !checked ? 0 : 100,
                duration: StyleSheet.value('$motionExpand'),
                useNativeDriver: false,
            }),
            Animated.timing(borderColorAnimatedValue, {
                toValue: disabled ? -100 : checked ? 100 : 0,
                duration: StyleSheet.value('$motionExpand'),
                useNativeDriver: false,
            }),
        ]);
        animation.start();
        return animation.stop;
    }, [bgColorAnimatedValue, borderColorAnimatedValue, checked, disabled]);
    const handlePress = () => {
        if (onChange)
            onChange(!checked);
    };
    return React.createElement(onChange ? Animated.createAnimatedComponent(Pressable) : Animated.View, Object.assign(Object.assign(Object.assign(Object.assign({}, useBanProps(useBanStylerProps(others), BANNED_PROPS)), useStyler(Object.assign({ style: styles(style.container, style[variant], {
            backgroundColor: bgColorAnimatedValue.interpolate({
                inputRange: [0, 100],
                outputRange: [StyleSheet.value('$colorBase'), StyleSheet.value('$colorAccent')],
            }),
            borderColor: borderColorAnimatedValue.interpolate({
                inputRange: [-100, 0, 100],
                outputRange: [
                    StyleSheet.value('$colorGrayscaleM'),
                    StyleSheet.value('$colorGrayscaleL'),
                    StyleSheet.value('$colorAccent'),
                ],
            }),
        }, others.style) }, others), Switch.displayName, useDevice())), testID(others.testID)), { accessibilityRole: variant, disabled, onPress: onChange ? handlePress : undefined }));
};
// ! Will be deprecated in v4 --------------------------------------------------
const Checkbox = (props) => React.createElement(Switch, Object.assign(Object.assign({}, props), { variant: SWITCH_VARIANT.CHECKBOX }));
const Radio = (props) => React.createElement(Switch, Object.assign(Object.assign({}, props), { variant: SWITCH_VARIANT.RADIO }));
// ! ---------------------------------------------------------------------------
Switch.displayName = 'Switch';
Checkbox.displayName = 'Checkbox';
Radio.displayName = 'Radio';
export { Switch, Checkbox, Radio };
