var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { FLEX_DIRECTION, SIZE } from '../../../hooks';
import { View } from '../../primitives';
const OptionContainer = (_a) => {
    var { children, horizontal = true, gutter = SIZE.M, maxSelected, multiSelection, name, option, onChange } = _a, others = __rest(_a, ["children", "horizontal", "gutter", "maxSelected", "multiSelection", "name", "option", "onChange"]);
    const handleMultiSelectionChange = (value) => {
        let nextValue = typeof option === 'string' ? [option] : option;
        nextValue = (nextValue === null || nextValue === void 0 ? void 0 : nextValue.includes(value)) ? nextValue.filter((item) => item !== value) : [...nextValue, value];
        if (maxSelected && maxSelected > 0 && nextValue.length > maxSelected) {
            nextValue.shift();
        }
        onChange(nextValue);
    };
    return (React.createElement(View, Object.assign({}, others, { flexDirection: horizontal ? FLEX_DIRECTION.ROW : FLEX_DIRECTION.COLUMN }), React.Children.map(children, (child, index) => React.cloneElement(child, Object.assign(Object.assign({ id: child.props.id, flex: horizontal ? SIZE.XS : undefined, name: child.props.name || name, checked: multiSelection ? option.includes(child.props.value) : child.props.value === option }, (index < children.length - 1
        ? horizontal
            ? { marginRight: gutter }
            : { marginBottom: gutter }
        : undefined)), { onChange: () => multiSelection ? handleMultiSelectionChange(child.props.value) : onChange(child.props.value) })))));
};
OptionContainer.displayName = 'OptionContainer';
export { OptionContainer };
