import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    icon: {
        fontFamily: '$iconFamily',
        fontStyle: 'normal',
        fontWeight: 'normal',
        minWidth: '$iconSize',
        width: '$iconSize',
        minHeight: '$iconSize',
        height: '$iconSize',
        fontSize: '$iconSize',
    },
});
