import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    item: {
        width: '$inputSize',
        height: '$inputSize',
        borderWidth: '$borderSize',
        borderStyle: '$borderStyle',
        borderRadius: '$inputSize / 2',
    },
    itemSmall: {
        width: '$spaceL - $spaceXS',
        height: '$spaceL - $spaceXS',
        borderWidth: '$borderSize',
        borderStyle: '$borderStyle',
        borderRadius: () => StyleSheet.value('$spaceL - $spaceXS') / 2,
    },
    value: {
        letterSpacing: 0,
    },
});
