var DOMAINS;
(function (DOMAINS) {
    DOMAINS["alignContent"] = "alignContent";
    DOMAINS["alignItems"] = "alignItems";
    DOMAINS["alignSelf"] = "alignSelf";
    DOMAINS["backgroundColor"] = "backgroundColor";
    DOMAINS["borderColor"] = "borderColor";
    DOMAINS["color"] = "color";
    DOMAINS["display"] = "display";
    DOMAINS["flex"] = "flex";
    DOMAINS["flexDirection"] = "flexDirection";
    DOMAINS["flexWrap"] = "flexWrap";
    DOMAINS["justifyContent"] = "justifyContent";
    DOMAINS["layer"] = "layer";
    DOMAINS["margin"] = "margin";
    DOMAINS["marginHorizontal"] = "marginHorizontal";
    DOMAINS["marginVertical"] = "marginVertical";
    DOMAINS["marginTop"] = "marginTop";
    DOMAINS["marginRight"] = "marginRight";
    DOMAINS["marginBottom"] = "marginBottom";
    DOMAINS["marginLeft"] = "marginLeft";
    DOMAINS["padding"] = "padding";
    DOMAINS["paddingHorizontal"] = "paddingHorizontal";
    DOMAINS["paddingVertical"] = "paddingVertical";
    DOMAINS["paddingTop"] = "paddingTop";
    DOMAINS["paddingRight"] = "paddingRight";
    DOMAINS["paddingBottom"] = "paddingBottom";
    DOMAINS["paddingLeft"] = "paddingLeft";
    DOMAINS["pointer"] = "pointer";
    DOMAINS["position"] = "position";
    DOMAINS["upperCase"] = "upperCase";
    DOMAINS["textAlign"] = "textAlign";
    DOMAINS["wide"] = "wide";
})(DOMAINS || (DOMAINS = {}));
var DOMAIN_SHORTCUT;
(function (DOMAIN_SHORTCUT) {
    DOMAIN_SHORTCUT["backgroundColor"] = "bg";
    DOMAIN_SHORTCUT["borderColor"] = "bc";
    DOMAIN_SHORTCUT["zIndex"] = "z";
})(DOMAIN_SHORTCUT || (DOMAIN_SHORTCUT = {}));
var ALIGN;
(function (ALIGN) {
    ALIGN["AROUND"] = "around";
    ALIGN["AUTO"] = "auto";
    ALIGN["BETWEEN"] = "between";
    ALIGN["STRETCH"] = "stretch";
    ALIGN["START"] = "start";
    ALIGN["CENTER"] = "center";
    ALIGN["END"] = "end";
    ALIGN["TOP"] = "top";
    ALIGN["RIGHT"] = "right";
    ALIGN["BOTTOM"] = "bottom";
    ALIGN["LEFT"] = "left";
})(ALIGN || (ALIGN = {}));
var COLOR;
(function (COLOR) {
    COLOR["BASE"] = "base";
    COLOR["CONTENT"] = "content";
    COLOR["ACCENT"] = "accent";
    COLOR["PRIMARY"] = "primary";
    COLOR["ALERT"] = "alert";
    COLOR["INFO"] = "info";
    COLOR["GRAYSCALE_XL"] = "grayscaleXL";
    COLOR["GRAYSCALE_L"] = "grayscaleL";
    COLOR["GRAYSCALE_M"] = "grayscaleM";
    COLOR["GRAYSCALE_S"] = "grayscaleS";
    COLOR["OVERLAY"] = "overlay";
    COLOR["TOUCHABLE"] = "touchable";
})(COLOR || (COLOR = {}));
var DISPLAY;
(function (DISPLAY) {
    DISPLAY["BLOCK"] = "block";
    DISPLAY["INLINE"] = "inline";
    DISPLAY["INLINE_BLOCK"] = "inlineBlock";
    DISPLAY["FLEX"] = "flex";
    DISPLAY["NONE"] = "none";
})(DISPLAY || (DISPLAY = {}));
var COORD;
(function (COORD) {
    COORD["TOP"] = "Top";
    COORD["RIGHT"] = "Right";
    COORD["BOTTOM"] = "Bottom";
    COORD["LEFT"] = "Left";
})(COORD || (COORD = {}));
var FLEX_DIRECTION;
(function (FLEX_DIRECTION) {
    FLEX_DIRECTION["COLUMN"] = "column";
    FLEX_DIRECTION["ROW"] = "row";
})(FLEX_DIRECTION || (FLEX_DIRECTION = {}));
var POINTER;
(function (POINTER) {
    POINTER["ALL"] = "all";
    POINTER["AUTO"] = "auto";
    POINTER["NONE"] = "none";
})(POINTER || (POINTER = {}));
var SIZE;
(function (SIZE) {
    SIZE["XXXS"] = "XXXS";
    SIZE["XXS"] = "XXS";
    SIZE["XS"] = "XS";
    SIZE["S"] = "S";
    SIZE["M"] = "M";
    SIZE["L"] = "L";
    SIZE["XL"] = "XL";
    SIZE["XXL"] = "XXL";
    SIZE["XXXL"] = "XXXL";
    SIZE["RESET"] = "0";
})(SIZE || (SIZE = {}));
var LAYOUT;
(function (LAYOUT) {
    LAYOUT["XXXS"] = "layoutXXXS";
    LAYOUT["XXS"] = "layoutXXS";
    LAYOUT["XS"] = "layoutXS";
    LAYOUT["S"] = "layoutS";
    LAYOUT["M"] = "layoutM";
    LAYOUT["L"] = "layoutL";
    LAYOUT["XL"] = "layoutXL";
    LAYOUT["XXL"] = "layoutXXL";
    LAYOUT["XXXL"] = "layoutXXXL";
})(LAYOUT || (LAYOUT = {}));
var POSITION;
(function (POSITION) {
    POSITION["ABSOLUTE"] = "absolute";
    POSITION["FIXED"] = "fixed";
    POSITION["RELATIVE"] = "relative";
    POSITION["STICKY"] = "sticky";
})(POSITION || (POSITION = {}));
var FLEX_WRAP;
(function (FLEX_WRAP) {
    FLEX_WRAP["WRAP"] = "wrap";
})(FLEX_WRAP || (FLEX_WRAP = {}));
var FONT;
(function (FONT) {
    FONT["HEADING"] = "heading";
    FONT["BODY"] = "body";
    FONT["ACTION"] = "action";
    FONT["DETAIL"] = "detail";
    FONT["BRAND"] = "brand";
})(FONT || (FONT = {}));
export { DOMAINS, DOMAIN_SHORTCUT, ALIGN, COLOR, COORD, DISPLAY, FLEX_DIRECTION, FLEX_WRAP, FONT, POINTER, POSITION, SIZE, LAYOUT, };
