import { ALIGN, COLOR } from '../../../../hooks';
import { OPTION_VARIANT } from '../Option.definition';
import { style } from '../Option.style';
export const getVariant = (variant, others = {}) => {
    const { checked, disabled, small, unselected } = others;
    const backgroundColor = checked ? (disabled ? COLOR.GRAYSCALE_M : COLOR.ACCENT) : undefined;
    const borderColor = disabled
        ? COLOR.GRAYSCALE_M
        : checked
            ? variant !== OPTION_VARIANT.BUTTON
                ? COLOR.ACCENT
                : COLOR.CONTENT
            : unselected
                ? COLOR.GRAYSCALE_L
                : variant !== OPTION_VARIANT.BUTTON
                    ? COLOR.CONTENT
                    : COLOR.GRAYSCALE_L;
    const color = disabled
        ? checked
            ? COLOR.GRAYSCALE_L
            : COLOR.GRAYSCALE_M
        : checked || (!disabled && !unselected)
            ? COLOR.CONTENT
            : COLOR.GRAYSCALE_L;
    switch (variant) {
        case OPTION_VARIANT.BUTTON:
            return {
                borderColor,
                label: {
                    color: color,
                    detail: true,
                    level: 1,
                },
                style: [style.button],
                styleInput: [style.buttonInput],
                visibleSwitch: true,
            };
        case OPTION_VARIANT.CARD:
            return {
                backgroundColor,
                borderColor,
                label: {
                    action: !small,
                    align: ALIGN.CENTER,
                    color: color,
                    detail: small,
                    upperCase: true,
                },
                style: [style.card, small && style.cardSmall],
                visibleSwitch: false,
            };
        case OPTION_VARIANT.PILL:
            return {
                backgroundColor,
                borderColor,
                label: {
                    action: !small,
                    align: ALIGN.CENTER,
                    color: color,
                    detail: small,
                    upperCase: true,
                },
                style: [style.pill],
                visibleSwitch: false,
            };
        case OPTION_VARIANT.ITEM:
            return {};
        default: {
            return {};
        }
    }
};
