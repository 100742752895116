import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    iconContainer: {
        alignSelf: 'center',
        height: '$spaceL',
    },
    text: {
        overflow: 'hidden',
    },
});
