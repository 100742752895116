import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    arrow: {
        left: '50%',
        transform: [{ rotateZ: '45deg' }],
        marginLeft: '$spaceM * -0.5',
        width: '$spaceM',
        height: '$spaceM',
    },
    arrowTop: {
        top: '100%',
        marginTop: '$spaceM * -0.5',
    },
    arrowBottom: {
        bottom: '100%',
        marginBottom: '$spaceM * -0.5',
    },
    bottom: {
        top: '100%',
        bottom: 'auto',
    },
    box: {
        shadowColor: '$elevationColor',
        shadowOffset: '$elevationOffset',
        shadowOpacity: '$elevationOpacityM',
        shadowRadius: '$elevationRadius',
    },
    button: {
        alignSelf: 'flex-end',
        position: 'absolute',
    },
    toolTip: undefined,
    top: {
        top: 'auto',
        bottom: '100%',
    },
});
