const BANNED_PROPS = ['id', 'style'];
var IMAGE_RESIZE_MODES;
(function (IMAGE_RESIZE_MODES) {
    IMAGE_RESIZE_MODES["contain"] = "contain";
    IMAGE_RESIZE_MODES["cover"] = "cover";
    IMAGE_RESIZE_MODES["stretch"] = "stretch";
    IMAGE_RESIZE_MODES["repeat"] = "repeat";
    IMAGE_RESIZE_MODES["center"] = "center";
})(IMAGE_RESIZE_MODES || (IMAGE_RESIZE_MODES = {}));
;
export { BANNED_PROPS, IMAGE_RESIZE_MODES };
