var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Platform, View as NativeView, } from 'react-native';
import { testID } from '../../../helpers';
import { useBanProps, useBanStylerProps, useDevice, useStyler } from '../../../hooks';
import { BANNED_PROPS } from './View.definition';
const View = React.forwardRef((_a, ref) => {
    var { children, displayName = 'View', role } = _a, others = __rest(_a, ["children", "displayName", "role"]);
    return (React.createElement(NativeView, Object.assign({}, useBanProps(useBanStylerProps(others), BANNED_PROPS), useStyler(others, displayName, useDevice()), testID(others.testID), { accessibilityRole: Platform.OS === 'web' ? role : undefined, pointerEvents: others.pointerEvents || others.pointer, ref: ref }), children));
});
View.displayName = 'View';
export { View };
