import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    inputField: undefined,
    // components
    border: {
        borderWidth: '$borderSize',
        borderStyle: '$borderStyle',
        fontFamily: '$fontBody',
    },
    content: {
        left: '$borderSize * -2',
        height: () => StyleSheet.value('$inputSize') - StyleSheet.value('$borderSize') * 2,
        justifyContent: 'center',
    },
    multiline: {
        height: undefined,
    },
    icon: {
        top: () => StyleSheet.value('$inputSize / 2') - StyleSheet.value('$iconSize / 2'),
        right: '$spaceM',
    },
    label: {
        left: 0,
        borderColor: 'transparent',
        borderStyle: '$borderStyle',
        borderRightWidth: '$spaceXS',
        borderLeftWidth: '$spaceXS',
        height: '$spaceM',
    },
    // states
    empty: undefined,
    focus: undefined,
    error: undefined,
    filled: undefined,
    disabled: undefined,
    // NATIVE environment
    contentEmpty: {
        opacity: 0,
    },
    labelEmpty: {
        borderRightWidth: 0,
        borderLeftWidth: 0,
        height: 'auto',
    },
});
