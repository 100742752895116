import { COLOR } from '../../../../hooks';
import { BUTTON_VARIANT } from '../Button.definition';
export const getVariant = (variant, others = {}) => {
    switch (variant) {
        case BUTTON_VARIANT.PRIMARY:
            return {
                color: COLOR.PRIMARY,
            };
        case BUTTON_VARIANT.SECONDARY:
            return {
                color: COLOR.BASE,
                outlined: true,
            };
        case BUTTON_VARIANT.CIRCULAR_PRIMARY:
            return {
                color: COLOR.PRIMARY,
                rounded: true,
                squared: true,
            };
        case BUTTON_VARIANT.CIRCULAR_SECONDARY:
            return {
                color: COLOR.BASE,
                outlined: true,
                rounded: true,
                squared: true,
            };
        // ! Will be deprecated in v4 ----------------------------------------------
        default: {
            const { circular, color, outlined, rounded, squared } = others;
            return { color, outlined, rounded: circular || rounded, squared: circular || squared };
        }
        // !------------------------------------------------------------------------
    }
};
