import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    tableRow: {
        flexDirection: 'row',
    },
    th: {
        borderBottomColor: '$colorGrayscaleM',
        borderBottomWidth: '$borderSize',
        borderStyle: '$borderStyle',
        flex: 1,
    },
    td: {
        borderBottomColor: '$colorGrayscaleM',
        borderBottomWidth: '$borderSize',
        borderStyle: '$borderStyle',
        flex: 1,
    },
});
