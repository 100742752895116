const STACK_REDUCER = {
    MOUNT: 'mount',
    SHOW: 'show',
    HIDE: 'hide',
    UNMOUNT: 'unmount',
    UNMOUNT_ALL: 'unmount_all',
};
const StackReducer = (state = {}, action = {}) => {
    const { type, id, component, props } = action;
    switch (type) {
        case STACK_REDUCER.MOUNT:
            return Object.assign(Object.assign({}, state), { [id]: { component: component, props: Object.assign(Object.assign({}, props), { isVisible: false }) } });
        case STACK_REDUCER.SHOW:
            return Object.assign(Object.assign({}, state), { [id]: Object.assign(Object.assign({}, state[id]), { props: Object.assign(Object.assign({}, state[id].props), { isVisible: true }) }) });
        case STACK_REDUCER.HIDE:
            return state[id] ? Object.assign(Object.assign({}, state), { [id]: Object.assign(Object.assign({}, state[id]), { props: Object.assign(Object.assign({}, state[id].props), { isVisible: false }) }) }) : state;
        case STACK_REDUCER.UNMOUNT:
            delete state[id];
            return Object.assign({}, state);
        case STACK_REDUCER.UNMOUNT_ALL:
            return {};
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};
export { STACK_REDUCER, StackReducer };
