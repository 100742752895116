const REGEX_DELIMITER = /\s*,\s*/;
const getLargestUri = (sources = []) => {
    const sizeUri = sources.find((source) => source.pixelDensity === 1).uri;
    const biggestSource = sources.sort((a, b) => {
        if (a.pixelDensity === b.pixelDensity) {
            return 0;
        }
        return a.pixelDensity < b.pixelDensity ? 1 : -1;
    })[0];
    return {
        uri: biggestSource.uri,
        sizeUri,
    };
};
const toSources = (srcSet = '') => srcSet.split(REGEX_DELIMITER).reduce((array, src) => {
    const [uri, pixelDensityRaw] = src.split(' ');
    if (!pixelDensityRaw)
        return [...array, { pixelDensity: 1, uri }];
    const pixelDensity = parseFloat(pixelDensityRaw.replace('x', ''));
    return [...array, { pixelDensity, uri }];
}, []);
// TODO Here should determine which is the best image depending of resolution
export const getUri = (src, srcSet) => (srcSet ? getLargestUri(toSources(srcSet)) : { uri: src, sizeUri: src });
