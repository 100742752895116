var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React from 'react';
import { Thumbnail } from '../../atoms';
import { ALIGN, DISPLAY, FLEX_DIRECTION, POSITION, SIZE, styles } from '../../../hooks';
import { Motion, MOTION_TIMING, MOTION_TYPES, PRESSABLE_OPACITY, Switch, SWITCH_VARIANT, Text, Touchable, View, } from '../../primitives';
import { Theme } from '../../../theming';
import { style } from './OptionImage.style';
const OptionImage = (_a) => {
    var { checked, children, disabled, height, id, imageResizeMode, name, src, srcSet, title, value, width, onChange } = _a, others = __rest(_a, ["checked", "children", "disabled", "height", "id", "imageResizeMode", "name", "src", "srcSet", "title", "value", "width", "onChange"]);
    const theme = Theme.get();
    return (React.createElement(Touchable, Object.assign({}, others, { onPress: !disabled && onChange ? () => onChange(value) : undefined, pressableOpacity: PRESSABLE_OPACITY.OPTION_IMAGE, style: styles({ height, width }, others.style) }),
        React.createElement(Thumbnail, Object.assign({}, {
            height,
            src,
            srcSet,
            resizeMode: imageResizeMode,
            style: styles(others.style),
            width,
        })),
        React.createElement(Text, { hidden: true }, title),
        onChange && (React.createElement(Switch, { "aria-describedby": id, checked: checked, display: DISPLAY.NONE, id: id, name: name, value: value, variant: SWITCH_VARIANT.RADIO })),
        React.createElement(Motion, { alignItems: ALIGN.CENTER, flexDirection: FLEX_DIRECTION.COLUMN, justifyContent: ALIGN.CENTER, layer: SIZE.XS, position: POSITION.ABSOLUTE, style: style.containerShadow, timing: MOTION_TIMING.SPRING, type: checked ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE, value: { borderWidth: checked ? theme.spaceS : 0 } },
            React.createElement(Motion, { style: style.contentShadow, timing: MOTION_TIMING.SPRING, type: checked ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE, value: { borderWidth: checked ? theme.spaceXXS : 0 } })),
        React.createElement(View, { layer: SIZE.XS, position: POSITION.ABSOLUTE, style: { width } }, children)));
};
OptionImage.displayName = 'OptionImage';
export { OptionImage };
