/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useLayoutEffect, useMemo, useReducer } from 'react';
import { DEFAULT_PORTAL } from './Portal.definition';
import { PORTAL_REDUCER_TYPE, PortalReducer } from './Portal.reducer';
const PortalContext = React.createContext(DEFAULT_PORTAL);
const usePortal = () => useContext(PortalContext);
const PortalProviderNotMemoized = ({ children }) => {
    const [state, dispatch] = useReducer(PortalReducer, []);
    const busy = state.filter(({ component: { props: { isVisible = false } = {} } = {} }) => isVisible).length > 0;
    const value = useMemo(() => ({
        busy,
        instance: true,
        mount: (id, component) => {
            dispatch({ type: PORTAL_REDUCER_TYPE.MOUNT, component, id });
        },
        unmount: (id) => {
            dispatch({ type: PORTAL_REDUCER_TYPE.UNMOUNT, id });
        },
    }), [busy]);
    return (React.createElement(PortalContext.Provider, { value: value },
        state.map(({ component }) => component),
        children));
};
const PortalProvider = React.memo(PortalProviderNotMemoized);
PortalProvider.displayName = 'AuroraPortalProvider';
const PortalNotMemoized = ({ children, id }) => {
    const portal = usePortal();
    useLayoutEffect(() => {
        portal.mount(id, children);
        return () => portal.unmount(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children, id]);
    return !portal.instance ? children : React.createElement(React.Fragment, null);
};
const Portal = React.memo(PortalNotMemoized);
Portal.displayName = 'AuroraPortal';
export { Portal, PortalContext, PortalProvider, usePortal };
