var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React from 'react';
import { COLOR, DISPLAY, FLEX_DIRECTION, POINTER, POSITION, SIZE, useBanStylerProps } from '../../../hooks';
import { Icon, Motion, MOTION_TYPES, Switch, SWITCH_VARIANT, Text, View } from '../../primitives';
import { style } from './InputOption.style';
export const InputOption = (_a) => {
    var { checked = false, children, hint, id, name, onChange, type = SWITCH_VARIANT.RADIO, value } = _a, others = __rest(_a, ["checked", "children", "hint", "id", "name", "onChange", "type", "value"]);
    return (React.createElement(View, Object.assign({}, others, { accessibilityLabel: hint, flexDirection: FLEX_DIRECTION.ROW }),
        React.createElement(View, { position: POSITION.RELATIVE, style: style.input },
            React.createElement(Switch, Object.assign(Object.assign({}, useBanStylerProps(Object.assign(Object.assign({}, others), { testID: undefined }))), { checked,
                id,
                name,
                value, variant: type, onChange })),
            React.createElement(Motion, { pointer: POINTER.NONE, position: POSITION.ABSOLUTE, style: style.icon, type: checked ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE, value: { opacity: checked ? 1 : 0, scale: checked ? 1 : 0 } },
                React.createElement(Icon, { color: others.disabled ? COLOR.GRAYSCALE_L : undefined, name: "tick" }))),
        (children || hint) && (React.createElement(View, { wide: true },
            children,
            !!hint && (React.createElement(Text, { color: COLOR.GRAYSCALE_L, detail: true, display: DISPLAY.INLINE_BLOCK, level: 2, marginLeft: SIZE.M, marginTop: SIZE.XS }, hint))))));
};
InputOption.displayName = 'InputOption';
