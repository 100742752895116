var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { ALIGN, POINTER, SIZE, styles } from '../../../hooks';
import { Icon, Motion, MOTION_TYPES, PRESSABLE_OPACITY, Text, Touchable } from '../../primitives';
import { Theme } from '../../../theming';
import { COLOR } from '../../../hooks/useStyler/styler.definition';
import { style } from './Button.style';
import { getVariant } from './helpers';
const Button = (_a) => {
    var { busy, children, disabled, icon, large, level = 3, small, upperCase = true, variant, wide = true, onPress } = _a, others = __rest(_a, ["busy", "children", "disabled", "icon", "large", "level", "small", "upperCase", "variant", "wide", "onPress"]);
    const [layout, setLayout] = useState();
    const { color, outlined, rounded, squared } = getVariant(variant, others);
    const backgroundColor = outlined ? color : disabled ? COLOR.GRAYSCALE_M : color || COLOR.PRIMARY;
    const colorContent = disabled
        ? outlined
            ? COLOR.GRAYSCALE_M
            : COLOR.GRAYSCALE_L
        : color === COLOR.CONTENT
            ? COLOR.BASE
            : COLOR.CONTENT;
    const pressableOpacity = disabled
        ? 1
        : backgroundColor === COLOR.PRIMARY
            ? PRESSABLE_OPACITY.BUTTON_PRIMARY
            : PRESSABLE_OPACITY.DEFAULT;
    const size = small ? 'Small' : large ? 'Large' : '';
    const handleLayout = ({ nativeEvent }) => {
        if (nativeEvent)
            setLayout(nativeEvent.layout);
    };
    const { spaceS } = Theme.get();
    return (React.createElement(Touchable, Object.assign({}, others, { backgroundColor: backgroundColor, borderColor: outlined ? (disabled ? COLOR.GRAYSCALE_M : COLOR.CONTENT) : undefined, paddingHorizontal: !squared ? (small ? SIZE.M : SIZE.L) : undefined, style: [
            style.base,
            large && style.large,
            small && style.small,
            !wide && style.fit,
            outlined && style.outlined,
            rounded && style[`rounded${size}`],
            squared && style[`squared${size}`],
            others.style,
        ], wide: wide, onLayout: handleLayout, onPress: !busy && !disabled ? onPress : undefined, pressableOpacity: pressableOpacity }),
        React.createElement(Motion, { delay: !busy, style: style.content, value: { translateY: busy ? -spaceS : 0, opacity: busy ? 0 : 1 } },
            icon && (React.createElement(Icon, { color: colorContent, name: icon, marginRight: !squared ? (small ? SIZE.S : SIZE.M) : undefined })),
            !squared ? (typeof children === 'string' ? (React.createElement(Text, { action: true, align: ALIGN.CENTER, level: level, color: colorContent, selectable: false, upperCase: upperCase }, children)) : (children)) : undefined),
        React.createElement(Motion, { backgroundColor: outlined ? COLOR.CONTENT : COLOR.BASE, disabled: !busy, layout: layout, pointerEvents: POINTER.NONE, style: styles(style.busy, small && style.small), type: MOTION_TYPES.SLOW, value: { width: busy ? '100%' : '0%' } }),
        React.createElement(Motion, { delay: busy, pointerEvents: POINTER.NONE, style: style.busyIcon, value: { translateY: busy ? 0 : spaceS, opacity: busy ? 1 : 0 } },
            React.createElement(Icon, { color: colorContent, name: "hourglass" }))));
};
Button.displayName = 'Button';
export { Button };
