import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    hidden: {
        display: 'none',
    },
    lineThrough: {
        textDecorationLine: 'line-through',
    },
    underlined: {
        textDecorationLine: 'underline',
    },
    // TODO: Should use hook and dont create a new style
    left: {
        textAlign: 'left',
    },
    center: {
        textAlign: 'center',
    },
    right: {
        textAlign: 'right',
    },
});
