import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    box: {
        alignItems: 'center',
    },
    content: {
        paddingBottom: '$spaceXL',
        paddingHorizontal: '$spaceXL',
    },
    iconClose: {
        marginRight: '$spaceM * -1',
    },
    fullHeight: {
        height: '100%',
    },
    modal: {
        bottom: 0,
        height: '100%',
        left: 0,
        top: 0,
        right: 0,
        overflow: 'hidden',
    },
});
