import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    container: {
        borderStyle: '$borderStyle',
        borderWidth: '$borderSize',
        height: '$inputOptionSize',
        width: '$inputOptionSize',
    },
    radio: {
        borderRadius: '$inputOptionSize / 2',
    },
});
