var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { PickerIOS } from '@react-native-picker/picker';
import React, { useImperativeHandle, useState } from 'react';
import { Modal, Pressable, Text, View } from 'react-native';
import { testID } from '../../../helpers';
import { POINTER } from '../../../hooks';
import { Icon } from '../Icon';
import { style } from './Select.style';
const SelectIOS = React.forwardRef((_a, ref) => {
    var { children, enabled, id, itemStyle, selectedValue, onValueChange } = _a, others = __rest(_a, ["children", "enabled", "id", "itemStyle", "selectedValue", "onValueChange"]);
    const [modal, setModal] = useState(false);
    const option = selectedValue
        ? children.find(({ props: { value = '' } }) => value.toString() === selectedValue.toString())
        : undefined;
    const { props: { label = '' } = {} } = option || children[0] || {};
    useImperativeHandle(ref, () => ({
        focus: () => setModal(true),
        blur: () => setModal(false),
    }));
    return (React.createElement(React.Fragment, null,
        React.createElement(Pressable, Object.assign({}, others, { disabled: !enabled, onPress: () => setModal(true) }),
            React.createElement(Text, { pointerEvents: POINTER.NONE, style: [itemStyle, style.itemIOS] }, label)),
        React.createElement(Modal, { animationType: "slide", transparent: true, visible: modal, onRequestClose: () => setModal(false) },
            React.createElement(View, { style: style.containerIOS },
                React.createElement(Pressable, Object.assign({ style: style.pressableIconIOS, onPress: () => setModal(false) }, testID('select-button-close')),
                    React.createElement(Icon, { name: "close" })),
                React.createElement(PickerIOS, Object.assign({}, { id, enabled, itemStyle, selectedValue, onValueChange }), children)))));
});
SelectIOS.displayName = 'SelectIOS';
export { SelectIOS };
