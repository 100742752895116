import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    item: {
        height: '$inputSize',
    },
    itemHighlight: {
        borderColor: 'transparent',
        borderWidth: '$borderSize',
        borderStyle: '$borderStyle',
        borderRadius: '$inputSize / 2',
    },
});
