var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import { Thumbnail } from '../../atoms';
import { KEYBOARD } from '../../../helpers';
import { styles, useDevice } from '../../../hooks';
import { Text, TEXT_ROLES, Touchable } from '../../primitives';
import { InputFile } from '../InputFile';
const InputImage = (_a) => {
    var { height, icon = 'expand', id, label, src, width, onChange, sourcesMessages } = _a, others = __rest(_a, ["height", "icon", "id", "label", "src", "width", "onChange", "sourcesMessages"]);
    const refInputFile = useRef(null);
    const { platform } = useDevice();
    useEffect(() => {
        if (refInputFile.current)
            refInputFile.current.value = '';
    }, [src]);
    const handleKeyUp = (event) => {
        if (refInputFile.current && (event.keyCode === KEYBOARD.ESPACE || event.keyCode === KEYBOARD.RETURN)) {
            refInputFile.current.press();
        }
        else {
            event.preventDefault();
        }
    };
    const handlePress = () => {
        if (refInputFile.current)
            refInputFile.current.press();
    };
    return (React.createElement(Touchable, Object.assign({}, others, { "aria-controls": id, onKeyUp: handleKeyUp, onPress: handlePress }),
        React.createElement(Thumbnail, Object.assign({}, { height, icon, width, style: styles(others.style) }, { src: src })),
        label && (React.createElement(Text, { role: TEXT_ROLES.label, hidden: true }, label)),
        React.createElement(InputFile, Object.assign({ accept: "image/*", nativeID: id, forwardedRef: refInputFile, hidden: true, onChange: onChange }, (platform.native && { sourcesMessages })))));
};
InputImage.displayName = 'InputImage';
export { InputImage };
