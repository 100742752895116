var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ButtonIcon } from '../../atoms';
import { ALIGN, COLOR, DISPLAY, FLEX_DIRECTION, POINTER, SIZE, styles } from '../../../hooks';
import { Motion, MOTION_TYPES, Text, View, VIEW_ROLES } from '../../primitives';
import { calcMotion } from './helpers';
import { style } from './Notification.style';
const Notification = (_a) => {
    var { children, color = COLOR.ALERT, isVisible = false, motion = 'top', nativeID, timeoutClose, text, timing, onClose } = _a, others = __rest(_a, ["children", "color", "isVisible", "motion", "nativeID", "timeoutClose", "text", "timing", "onClose"]);
    const [layout, setLayout] = useState();
    const handleLayout = (event) => {
        if (event)
            setLayout(event.nativeEvent.layout);
    };
    useEffect(() => {
        if (!timeoutClose || !onClose)
            return;
        let idTimeOut;
        if (isVisible)
            idTimeOut = setTimeout(onClose, timeoutClose);
        else
            clearTimeout(idTimeOut);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);
    const colorContent = color === COLOR.CONTENT ? COLOR.BASE : undefined;
    return (React.createElement(Motion, { layout: layout, pointer: !isVisible ? POINTER.NONE : undefined, timing: timing, type: isVisible ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE, value: motion ? calcMotion({ isVisible, motion }) : undefined, wide: true, role: VIEW_ROLES.aside, onLayout: handleLayout },
        React.createElement(View, Object.assign({}, others, { style: styles(others.style), backgroundColor: color, nativeID: nativeID }),
            React.createElement(View, { alignItems: ALIGN.CENTER, flexDirection: FLEX_DIRECTION.ROW, paddingLeft: SIZE.M, paddingRight: SIZE.S, paddingVertical: SIZE.S, style: styles(style.notification), testID: nativeID ? `${nativeID}-container` : undefined },
                React.createElement(View, { flex: SIZE.XS, flexDirection: FLEX_DIRECTION.COLUMN, paddingTop: SIZE.XXS, paddingBottom: SIZE.XS, wide: true },
                    text && (React.createElement(Text, { color: colorContent, detail: true, display: DISPLAY.BLOCK, level: 2 }, text)),
                    children),
                onClose && (React.createElement(ButtonIcon, { color: colorContent, name: "close", style: style.button, onPress: onClose, testID: nativeID ? `${nativeID}-close` : undefined }))))));
};
Notification.displayName = 'Notification';
export { Notification };
