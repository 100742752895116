var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import { LinearGradient } from 'expo-linear-gradient';
import React, { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import { COLOR, FLEX_DIRECTION, POINTER, POSITION, SIZE, styles, useStyler } from '../../../hooks';
import { ScrollView, View, VIEW_ROLES } from '../../primitives';
import { Theme } from '../../../theming';
import { BASE_TRANSPARENT, VISIBLE_ITEMS } from './WheelPicker.definition';
import { style } from './WheelPicker.style';
import { WheelPickerItem } from './WheelPickerItem';
const WheelPicker = (_a) => {
    var { disabled = false, onChange, options = [], selected, selectOnScroll = true, testID } = _a, others = __rest(_a, ["disabled", "onChange", "options", "selected", "selectOnScroll", "testID"]);
    const [current, setCurrent] = useState();
    const { colorBase, inputSize } = Theme.get();
    useEffect(() => {
        if (selected !== current)
            setCurrent(selected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);
    const handlePressItem = (nextOption) => {
        handleChange(nextOption);
    };
    const handleScroll = ({ Y }) => {
        handleChange(Y < inputSize ? 0 : Math.round(Y / inputSize));
    };
    const handleChange = (nextOption) => {
        if (nextOption !== current) {
            setCurrent(nextOption);
            if (onChange)
                onChange(nextOption);
        }
    };
    const scrollable = options.length > VISIBLE_ITEMS;
    const gradientProps = useStyler({ wide: true, style: style.gradient });
    return (React.createElement(View, Object.assign({}, others, { position: POSITION.RELATIVE, wide: true }),
        React.createElement(ScrollView, { animated: !disabled && Platform.OS === 'web', height: (scrollable ? VISIBLE_ITEMS : options.length) * inputSize, nestedScrollEnabled: true, role: VIEW_ROLES.ul, scrollTo: (current || 0) * inputSize, snapInterval: inputSize, style: styles(others.style), onScroll: !disabled && selectOnScroll && scrollable ? handleScroll : undefined, testID: testID },
            scrollable && (React.createElement(React.Fragment, null,
                React.createElement(WheelPickerItem, { disabled: true, scrollable: true }),
                React.createElement(WheelPickerItem, { disabled: true, scrollable: true }))),
            options.map((option, index) => (React.createElement(WheelPickerItem, { checked: index === current, disabled: disabled, key: option, scrollable: scrollable, testID: `${testID}-${index}`, title: option, onPress: () => handlePressItem(index) }))),
            scrollable && (React.createElement(React.Fragment, null,
                React.createElement(WheelPickerItem, { disabled: true, scrollable: true }),
                React.createElement(WheelPickerItem, { disabled: true, scrollable: true })))),
        scrollable && (React.createElement(View, { flexDirection: FLEX_DIRECTION.COLUMN, layer: SIZE.XS, pointerEvents: POINTER.NONE, position: POSITION.ABSOLUTE, style: style.dartBoard, wide: true },
            React.createElement(LinearGradient, Object.assign({}, gradientProps, { colors: [colorBase, BASE_TRANSPARENT] })),
            React.createElement(View, { borderColor: disabled ? COLOR.GRAYSCALE_M : COLOR.CONTENT, style: style.selector, wide: true }),
            React.createElement(LinearGradient, Object.assign({}, gradientProps, { colors: [BASE_TRANSPARENT, colorBase] }))))));
};
WheelPicker.displayName = 'WheelPicker';
export { WheelPicker };
