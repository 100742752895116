var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import { testID as getTestID } from '../../../helpers';
import { COLOR, DISPLAY, POINTER, POSITION, SIZE, styles, useBanProps, useBanStylerProps, useId } from '../../../hooks';
import { Icon, Motion, Text, View } from '../../primitives';
import { Theme } from '../../../theming';
import { useField } from './helpers';
import { BANNED_PROPS } from './InputField.definition';
import { HOC } from './InputField.hoc';
import { style } from './InputField.style';
const MOTION_M_DELAY = 300;
const InputField = React.forwardRef((_a, ref) => {
    var { disabled = false, error = false, fieldset, hint, icon, id, label, multiLine = false, name, style: inheritStyle, type, onBlur, onChange, onFocus, options, testID } = _a, others = __rest(_a, ["disabled", "error", "fieldset", "hint", "icon", "id", "label", "multiLine", "name", "style", "type", "onBlur", "onChange", "onFocus", "options", "testID"]);
    const idField = useId(id);
    const { handleChange, filled } = useField(Object.assign({ onChange }, others));
    const [focus, setFocus] = useState(false);
    const [timeoutID, setTimeoutID] = useState(undefined);
    const handleBlur = (event) => {
        if (!focus)
            return;
        if (event && event.persist)
            event.persist();
        setFocus(false);
        setTimeoutID(onBlur ? setTimeout(() => onBlur(event), MOTION_M_DELAY) : undefined);
    };
    const handleFocus = (event) => {
        if (timeoutID)
            clearTimeout(timeoutID);
        if (!focus) {
            setFocus(true);
            if (onFocus)
                onFocus(event);
        }
    };
    const { spaceXXS, spaceXS, spaceS, spaceM } = Theme.get();
    const empty = !filled && !focus;
    let color = COLOR.GRAYSCALE_L;
    if (disabled)
        color = COLOR.GRAYSCALE_M;
    else if (error)
        color = COLOR.PRIMARY;
    else if (focus)
        color = COLOR.CONTENT;
    return (React.createElement(View, Object.assign({}, useBanProps(others, [...BANNED_PROPS, 'accessibilityLabel']), { style: styles(style.inputField, focus && style.focus, disabled && style.disabled, empty && !error && style.empty, error && style.error, (filled || error) && style.filled, inheritStyle) }),
        React.createElement(View, Object.assign({ backgroundColor: COLOR.BASE, borderColor: error ? COLOR.PRIMARY : disabled ? COLOR.GRAYSCALE_M : focus ? COLOR.CONTENT : COLOR.GRAYSCALE_L, position: POSITION.RELATIVE, style: style.border }, getTestID(testID)),
            React.createElement(HOC, Object.assign({}, useBanStylerProps(others), { color: color, disabled: disabled, fieldset: fieldset, id: idField, label: label, name: name, multiLine: multiLine, onBlur: handleBlur, onChange: handleChange, onFocus: handleFocus, options: options, paddingLeft: SIZE.M, paddingRight: icon ? SIZE.XXXL : SIZE.M, position: POSITION.RELATIVE, ref: ref, style: styles(style.content, multiLine && style.multiline, empty && !error && style.contentEmpty), type: type, testID: testID })),
            React.createElement(Motion, { backgroundColor: COLOR.BASE, borderColor: !empty ? COLOR.ACCENT : undefined, color: color, display: DISPLAY.BLOCK, layer: SIZE.XS, marginHorizontal: SIZE.M, pointer: POINTER.NONE, position: POSITION.ABSOLUTE, style: styles(style.label, empty && !error && style.labelEmpty), value: {
                    translateY: empty && !error ? spaceM : (Number(spaceS) + Number(spaceXXS) + 1) * -1,
                    translateX: empty && !error ? 0 : -Number(spaceXS),
                } },
                React.createElement(Text, { role: "label", color: color, detail: true, ellipsizeMode: true, htmlFor: idField, level: empty && !error ? 1 : 2 }, label)),
            icon && (React.createElement(Icon, { color: color, name: icon, pointerEvents: POINTER.NONE, position: POSITION.ABSOLUTE, style: style.icon }))),
        !!hint && (React.createElement(Text, { color: color, detail: true, display: DISPLAY.INLINE_BLOCK, level: 2, marginLeft: SIZE.M, marginTop: SIZE.XS }, hint))));
});
InputField.displayName = 'InputField';
export { InputField };
