/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
import { Icon, View, VIEW_ROLES } from '../../primitives';
import { style } from './Spinner.style';
import SpinnerImage from './Svg/SpinnerSvg';
const Spinner = ({ accessibilityLabel, testID }) => {
    const spinAnim = useRef(new Animated.Value(0)).current;
    useEffect(() => {
        const loop = Animated.loop(Animated.timing(spinAnim, {
            toValue: 1,
            duration: 700,
            useNativeDriver: false,
            easing: Easing.linear,
        }));
        loop.start();
        return () => loop.stop();
    }, [spinAnim]);
    const spin = spinAnim.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
    });
    return (React.createElement(View, { role: VIEW_ROLES.div, style: style.spinner, accessibilityLabel: accessibilityLabel, testID: testID },
        React.createElement(Animated.View, { style: [style.image, { transform: [{ rotate: spin }] }] },
            React.createElement(SpinnerImage, null)),
        React.createElement(View, { style: style.iconContainer },
            React.createElement(Icon, { name: "lookiero", style: style.icon }))));
};
Spinner.displayName = 'Spinner';
export { Spinner };
