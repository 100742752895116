var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { BOOLEAN_TYPES } from '../Form.definition';
import { getField } from './getField';
export const getChildrenValues = (children) => {
    const values = {};
    React.Children.forEach(children, (_a = {}) => {
        var _b = _a.props, _c = _b === void 0 ? {} : _b, { checked = false, defaultChecked = false, defaultValue = undefined, type = undefined, value = undefined } = _c, props = __rest(_c, ["checked", "defaultChecked", "defaultValue", "type", "value"]);
        const field = getField(props);
        if (field)
            values[field] = BOOLEAN_TYPES.includes(type) ? checked || defaultChecked : value || defaultValue;
    });
    return values;
};
