var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { View } from '../../primitives';
const AccordionContainer = (_a) => {
    var { children, onPress } = _a, others = __rest(_a, ["children", "onPress"]);
    const [active, setActive] = useState(others.active);
    useEffect(() => {
        setActive(others.active);
    }, [others.active]);
    const handlePress = (index) => {
        setActive(() => {
            onPress && onPress(index);
            return active !== index ? index : undefined;
        });
    };
    return (React.createElement(View, Object.assign({}, others, { wide: true }), React.Children.map(children, (child, index) => 
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    React.cloneElement(child, {
        active: index === active,
        key: index,
        onPress: () => handlePress(index),
    }))));
};
AccordionContainer.displayName = 'AccordionContainer';
export { AccordionContainer };
