var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { ALIGN, COLOR, FLEX_DIRECTION, SIZE } from '../../../hooks';
import { View, VIEW_ROLES } from '../../primitives';
import { FooterItem } from './FooterItem';
export const Footer = (_a) => {
    var { children, color = COLOR.BASE, container: Container = React.Fragment, onChange, value } = _a, others = __rest(_a, ["children", "color", "container", "onChange", "value"]);
    return (React.createElement(View, Object.assign({}, others, { backgroundColor: color, role: VIEW_ROLES.footer, wide: true }),
        React.createElement(Container, null,
            React.createElement(View, { alignItems: ALIGN.CENTER, flexDirection: FLEX_DIRECTION.ROW, justifyContent: ALIGN.BETWEEN, paddingHorizontal: SIZE.S }, React.Children.toArray(children).map((child) => {
                if (!React.isValidElement(child))
                    return null;
                return (React.createElement(FooterItem, Object.assign({ key: child.props.value }, child.props, { active: value ? value.includes(child.props.value) : false, onPress: onChange ? () => onChange(child.props.value) : undefined })));
            })))));
};
Footer.displayName = 'Footer';
