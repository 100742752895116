import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    base: {
        alignContent: 'center',
        borderRadius: '$borderRadius',
        flex: 1,
        height: '$inputSize',
        justifyContent: 'center',
        maxHeight: '$inputSize',
        minHeight: '$inputSize',
        overflow: 'hidden',
        position: 'relative',
    },
    busy: {
        height: '100%',
        left: 0,
        opacity: 0.3,
        position: 'absolute',
        top: 0,
        zIndex: 1,
    },
    busyIcon: {
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
    },
    content: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    fit: {
        alignSelf: 'center',
    },
    large: {
        height: '$inputSizeLarge',
        maxHeight: '$inputSizeLarge',
        minHeight: '$inputSizeLarge',
    },
    outlined: {
        borderWidth: '$borderSize',
        borderStyle: '$borderStyle',
    },
    rounded: {
        borderRadius: '$inputSize / 2',
    },
    roundedSmall: {
        borderRadius: '$inputSizeSmall / 2',
    },
    roundedLarge: {
        borderRadius: '$inputSizeLarge / 2',
    },
    small: {
        height: '$inputSizeSmall',
        maxHeight: '$inputSizeSmall',
        minHeight: '$inputSizeSmall',
    },
    squared: {
        width: '$inputSize',
    },
    squaredSmall: {
        width: '$inputSizeSmall',
    },
    squaredLarge: {
        width: '$inputSizeLarge',
    },
});
