var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { FLEX_DIRECTION, POSITION, SIZE } from '../../../hooks';
import { View, VIEW_ROLES } from '../../primitives';
const StepContainer = (_a) => {
    var { active = 0, children, forceActive = false, horizontal = true } = _a, others = __rest(_a, ["active", "children", "forceActive", "horizontal"]);
    return (React.createElement(View, Object.assign({}, others, { role: VIEW_ROLES.ul, position: POSITION.RELATIVE, flex: SIZE.XS, flexDirection: horizontal ? FLEX_DIRECTION.ROW : FLEX_DIRECTION.COLUMN }), React.Children.map(children, (child, index) => React.cloneElement(child, Object.assign(Object.assign({}, child.props), { active: forceActive || index <= active, forceActive: forceActive, checked: index < active, first: index === 0, horizontal: horizontal, last: index === React.Children.count(children) - 1 })))));
};
StepContainer.displayName = 'StepContainer';
export { StepContainer };
