var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React from 'react';
import { ButtonIcon } from '../../atoms';
import { ALIGN, COLOR, DISPLAY, FLEX_DIRECTION, SIZE } from '../../../hooks';
import { Text, Touchable } from '../../primitives';
import { style } from './FooterItem.style';
export const FooterItem = (_a) => {
    var { active, color = COLOR.GRAYSCALE_XL, colorActiveIcon = COLOR.PRIMARY, colorActiveText = COLOR.CONTENT, icon, onPress, text, notification } = _a, others = __rest(_a, ["active", "color", "colorActiveIcon", "colorActiveText", "icon", "onPress", "text", "notification"]);
    return (React.createElement(Touchable, Object.assign({}, others, { alignItems: ALIGN.CENTER, flex: SIZE.XS, flexDirection: FLEX_DIRECTION.COLUMN, justifyContent: ALIGN.BETWEEN, paddingVertical: SIZE.S, onPress: onPress }),
        icon && (React.createElement(ButtonIcon, { color: active ? colorActiveIcon : color, colorNotification: colorActiveIcon, name: icon, notification: notification, style: style.iconContainer, small: true })),
        text && (React.createElement(Text, { align: ALIGN.CENTER, color: active ? colorActiveText : color, detail: true, display: DISPLAY.BLOCK, ellipsizeMode: true, level: 2, style: style.text }, text))));
};
FooterItem.displayName = 'FooterItem';
