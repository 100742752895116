import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    header: {
        height: '$spaceXXXL',
        maxWidth: '$layoutMaxWidth',
        width: '100%',
    },
    actions: {
        marginLeft: 'auto',
    },
    fullWidth: {
        maxWidth: '100%',
    },
    logo: {
        top: 0,
        left: 0,
    },
    title: {
        left: '$spaceXXXL',
        right: '$spaceXXXL',
    },
});
