export const SPACING_STYLESHEETS = {
    marginXXS: {
        margin: '$spaceXXS',
    },
    marginXS: {
        margin: '$spaceXS',
    },
    marginS: {
        margin: '$spaceS',
    },
    marginM: {
        margin: '$spaceM',
    },
    marginL: {
        margin: '$spaceL',
    },
    marginXL: {
        margin: '$spaceXL',
    },
    marginXXL: {
        margin: '$spaceXXL',
    },
    marginXXXL: {
        margin: '$spaceXXXL',
    },
    margin0: {
        margin: 0,
    },
    marginTop0: {
        marginTop: 0,
    },
    marginRight0: {
        marginRight: 0,
    },
    marginBottom0: {
        marginBottom: 0,
    },
    marginLeft0: {
        marginLeft: 0,
    },
    marginTopXXS: {
        marginTop: '$spaceXXS',
    },
    marginRightXXS: {
        marginRight: '$spaceXXS',
    },
    marginBottomXXS: {
        marginBottom: '$spaceXXS',
    },
    marginLeftXXS: {
        marginLeft: '$spaceXXS',
    },
    marginTopXS: {
        marginTop: '$spaceXS',
    },
    marginRightXS: {
        marginRight: '$spaceXS',
    },
    marginBottomXS: {
        marginBottom: '$spaceXS',
    },
    marginLeftXS: {
        marginLeft: '$spaceXS',
    },
    marginTopS: {
        marginTop: '$spaceS',
    },
    marginRightS: {
        marginRight: '$spaceS',
    },
    marginBottomS: {
        marginBottom: '$spaceS',
    },
    marginLeftS: {
        marginLeft: '$spaceS',
    },
    marginTopM: {
        marginTop: '$spaceM',
    },
    marginRightM: {
        marginRight: '$spaceM',
    },
    marginBottomM: {
        marginBottom: '$spaceM',
    },
    marginLeftM: {
        marginLeft: '$spaceM',
    },
    marginTopL: {
        marginTop: '$spaceL',
    },
    marginRightL: {
        marginRight: '$spaceL',
    },
    marginBottomL: {
        marginBottom: '$spaceL',
    },
    marginLeftL: {
        marginLeft: '$spaceL',
    },
    marginTopXL: {
        marginTop: '$spaceXL',
    },
    marginRightXL: {
        marginRight: '$spaceXL',
    },
    marginBottomXL: {
        marginBottom: '$spaceXL',
    },
    marginLeftXL: {
        marginLeft: '$spaceXL',
    },
    marginTopXXL: {
        marginTop: '$spaceXXL',
    },
    marginRightXXL: {
        marginRight: '$spaceXXL',
    },
    marginBottomXXL: {
        marginBottom: '$spaceXXL',
    },
    marginLeftXXL: {
        marginLeft: '$spaceXXL',
    },
    marginTopXXXL: {
        marginTop: '$spaceXXXL',
    },
    marginRightXXXL: {
        marginRight: '$spaceXXXL',
    },
    marginBottomXXXL: {
        marginBottom: '$spaceXXXL',
    },
    marginLeftXXXL: {
        marginLeft: '$spaceXXXL',
    },
    paddingXXS: {
        padding: '$spaceXXS',
    },
    paddingXS: {
        padding: '$spaceXS',
    },
    paddingS: {
        padding: '$spaceS',
    },
    paddingM: {
        padding: '$spaceM',
    },
    paddingL: {
        padding: '$spaceL',
    },
    paddingXL: {
        padding: '$spaceXL',
    },
    paddingXXL: {
        padding: '$spaceXXL',
    },
    paddingXXXL: {
        padding: '$spaceXXXL',
    },
    padding0: {
        padding: 0,
    },
    paddingTop0: {
        paddingTop: 0,
    },
    paddingRight0: {
        paddingRight: 0,
    },
    paddingBottom0: {
        paddingBottom: 0,
    },
    paddingLeft0: {
        paddingLeft: 0,
    },
    paddingTopXXS: {
        paddingTop: '$spaceXXS',
    },
    paddingRightXXS: {
        paddingRight: '$spaceXXS',
    },
    paddingBottomXXS: {
        paddingBottom: '$spaceXXS',
    },
    paddingLeftXXS: {
        paddingLeft: '$spaceXXS',
    },
    paddingTopXS: {
        paddingTop: '$spaceXS',
    },
    paddingRightXS: {
        paddingRight: '$spaceXS',
    },
    paddingBottomXS: {
        paddingBottom: '$spaceXS',
    },
    paddingLeftXS: {
        paddingLeft: '$spaceXS',
    },
    paddingTopS: {
        paddingTop: '$spaceS',
    },
    paddingRightS: {
        paddingRight: '$spaceS',
    },
    paddingBottomS: {
        paddingBottom: '$spaceS',
    },
    paddingLeftS: {
        paddingLeft: '$spaceS',
    },
    paddingTopM: {
        paddingTop: '$spaceM',
    },
    paddingRightM: {
        paddingRight: '$spaceM',
    },
    paddingBottomM: {
        paddingBottom: '$spaceM',
    },
    paddingLeftM: {
        paddingLeft: '$spaceM',
    },
    paddingTopL: {
        paddingTop: '$spaceL',
    },
    paddingRightL: {
        paddingRight: '$spaceL',
    },
    paddingBottomL: {
        paddingBottom: '$spaceL',
    },
    paddingLeftL: {
        paddingLeft: '$spaceL',
    },
    paddingTopXL: {
        paddingTop: '$spaceXL',
    },
    paddingRightXL: {
        paddingRight: '$spaceXL',
    },
    paddingBottomXL: {
        paddingBottom: '$spaceXL',
    },
    paddingLeftXL: {
        paddingLeft: '$spaceXL',
    },
    paddingTopXXL: {
        paddingTop: '$spaceXXL',
    },
    paddingRightXXL: {
        paddingRight: '$spaceXXL',
    },
    paddingBottomXXL: {
        paddingBottom: '$spaceXXL',
    },
    paddingLeftXXL: {
        paddingLeft: '$spaceXXL',
    },
    paddingTopXXXL: {
        paddingTop: '$spaceXXXL',
    },
    paddingRightXXXL: {
        paddingRight: '$spaceXXXL',
    },
    paddingBottomXXXL: {
        paddingBottom: '$spaceXXXL',
    },
    paddingLeftXXXL: {
        paddingLeft: '$spaceXXXL',
    },
};
