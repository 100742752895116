var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import { ButtonIcon } from '../../atoms';
import { ALIGN, COLOR, FLEX_DIRECTION, POINTER, POSITION, SIZE, styles, useId } from '../../../hooks';
import { Motion, MOTION_TIMING, Text, TEXT_ROLES, View, VIEW_ROLES } from '../../primitives';
import { style } from './ToolTip.style';
export const ToolTip = (_a) => {
    var { align = ALIGN.TOP, color = COLOR.INFO, text, title, children, isVisible = true } = _a, others = __rest(_a, ["align", "color", "text", "title", "children", "isVisible"]);
    const id = useId();
    const [visible, setVisible] = useState(isVisible);
    const toggleVisible = () => {
        setVisible(!visible);
    };
    const colorContent = color === COLOR.CONTENT ? COLOR.BASE : undefined;
    return (React.createElement(View, Object.assign({}, others, { style: styles(others.style) }),
        React.createElement(Motion, { layer: SIZE.M, pointer: !visible ? POINTER.NONE : undefined, position: POSITION.ABSOLUTE, role: VIEW_ROLES.section, style: styles(style.toolTip, style[align], others.style), tabIndex: 0, timing: MOTION_TIMING.SPRING, value: {
                opacity: visible ? 1 : 0,
                translateY: visible ? `${align === 'top' ? '-' : ''}1rem` : 0,
            }, wide: true },
            React.createElement(View, { backgroundColor: color, style: style.box, wide: true },
                React.createElement(ButtonIcon, { color: colorContent, layer: SIZE.L, name: "close", style: style.button, onPress: toggleVisible }),
                React.createElement(View, { flexDirection: FLEX_DIRECTION.COLUMN, padding: SIZE.M },
                    title && (React.createElement(Text, { role: TEXT_ROLES.strong, align: ALIGN.CENTER, color: colorContent, detail: true, marginBottom: SIZE.XS, upperCase: true }, title)),
                    React.createElement(Text, { align: ALIGN.CENTER, color: colorContent, detail: true, level: 2 }, text))),
            React.createElement(View, { backgroundColor: color, pointer: POINTER.NONE, position: POSITION.ABSOLUTE, style: styles(style.arrow, align === ALIGN.TOP ? style.arrowTop : style.arrowBottom) })),
        React.Children.map(children, (child) => React.cloneElement(child, {
            onPress: toggleVisible,
            'aria-describedby': id,
        }))));
};
ToolTip.displayName = 'ToolTip';
