var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React from 'react';
import { ALIGN, COLOR, FLEX_DIRECTION, POSITION, SIZE, styles } from '../../../hooks';
import { Motion, MOTION_TYPES, Touchable } from '../../primitives';
import { style } from './Overlay.style';
const Overlay = (_a) => {
    var { children, isVisible, nativeID, onPress } = _a, others = __rest(_a, ["children", "isVisible", "nativeID", "onPress"]);
    return (React.createElement(Motion, Object.assign({}, others, { backgroundColor: COLOR.OVERLAY, delay: !isVisible, layer: SIZE.M, position: POSITION.FIXED, style: styles(style.overlay, others.style), type: isVisible ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE, value: { opacity: isVisible ? 1 : 0 } }),
        React.createElement(Touchable, { alignItems: ALIGN.CENTER, flexDirection: FLEX_DIRECTION.COLUMN, justifyContent: ALIGN.CENTER, nativeID: nativeID, style: style.touchable, tabIndex: 0, onPress: onPress }, children)));
};
Overlay.displayName = 'Overlay';
export { Overlay };
