var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React from 'react';
import { Linking, Platform } from 'react-native';
import { COLOR, POINTER, useKeyboard } from '../../../hooks';
import { Text, Touchable } from '../../primitives';
import { ROLES } from '../../primitives/Touchable/Touchable.definition';
const Link = (_a) => {
    var { align, children, color = COLOR.CONTENT, href, target, testID, underlined, onPress } = _a, others = __rest(_a, ["align", "children", "color", "href", "target", "testID", "underlined", "onPress"]);
    const { isKeyboardOpen, closeKeyboard } = useKeyboard();
    const hasHref = href && href.length > 0;
    const handlePress = () => {
        if (isKeyboardOpen)
            closeKeyboard();
        if (onPress)
            onPress({ href });
        if (Platform.OS !== 'web' && hasHref)
            Linking.openURL(href);
    };
    const hrefAttrs = hasHref && target ? { target } : undefined;
    return (React.createElement(Touchable, { alignSelf: align, href: hasHref ? href : undefined, hrefAttrs: hrefAttrs, role: ROLES.link, testID: testID, onPress: handlePress },
        React.createElement(Text, Object.assign({}, others, { align: align, borderColor: underlined ? color : undefined, color: color, pointerEvents: POINTER.AUTO, selectable: false, underlined: underlined }), children)));
};
Link.displayName = 'Link';
export { Link };
