import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    input: Object.assign({ color: '$colorContent', flex: 0, minHeight: '$inputSize', height: '$inputSize', paddingTop: '$fontInputPaddingTop', paddingRight: '$fontInputPaddingRight', paddingBottom: '$fontInputPaddingBottom', paddingLeft: '$fontInputPaddingLeft', textAlignVertical: 'center' }, Platform.select({
        web: {
            outlineWidth: 0,
        },
    })),
    disabled: {
        color: '$colorGrayscaleM',
    },
    multiline: {
        height: 'auto',
        minHeight: '$layoutXL + $spaceM',
        paddingTop: '$spaceM + $fontInputPaddingTop',
        paddingBottom: '$spaceM',
        textAlignVertical: 'top',
    },
});
