var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React from 'react';
import { SafeAreaView as NativeSafeAreaView } from 'react-native';
import { testID } from '../../../helpers';
import { useBanStylerProps, useStyler } from '../../../hooks';
const SafeAreaView = (_a) => {
    var { children } = _a, others = __rest(_a, ["children"]);
    return (React.createElement(NativeSafeAreaView, Object.assign({}, useBanStylerProps(others), useStyler(others), testID(others.testID)), children));
};
SafeAreaView.displayName = 'SafeAreaView';
export { SafeAreaView };
