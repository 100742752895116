var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useMemo, useReducer } from 'react';
import { SafeAreaView } from 'react-native';
import { COLOR, POSITION, SIZE, styles, useDevice } from '../../hooks';
import { Theme } from '../../theming';
import { View } from '../../components/primitives/View';
import { DEFAULT_STACK } from './Stack.definition';
import { STACK_REDUCER, StackReducer } from './Stack.reducer';
import { style } from './Stack.style';
const StackContext = React.createContext(DEFAULT_STACK);
const useStack = () => useContext(StackContext);
const StackProvider = (_a) => {
    var { children } = _a, others = __rest(_a, ["children"]);
    const { screen } = useDevice();
    const [state, dispatch] = useReducer(StackReducer, {});
    const { motionExpand } = Theme.get();
    const value = useMemo(() => ({
        show: (id, component, props) => {
            dispatch({ type: STACK_REDUCER.MOUNT, component: component, id: id, props: props });
            setTimeout(() => {
                dispatch({ type: STACK_REDUCER.SHOW, id: id });
            }, 0);
        },
        alert: (id, component, props) => {
            value.show(id, component, Object.assign(Object.assign({}, props), { color: COLOR.ALERT }));
        },
        info: (id, component, props) => {
            value.show(id, component, Object.assign(Object.assign({}, props), { color: COLOR.INFO }));
        },
        success: (id, component, props) => {
            value.show(id, component, Object.assign(Object.assign({}, props), { color: COLOR.ACCENT }));
        },
        hide: (id) => {
            dispatch({ type: STACK_REDUCER.HIDE, id: id });
            setTimeout(() => {
                dispatch({ type: STACK_REDUCER.UNMOUNT, id: id });
            }, motionExpand);
        },
        wipe: () => {
            dispatch({ type: STACK_REDUCER.UNMOUNT_ALL });
        },
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]);
    return (React.createElement(StackContext.Provider, { value: value },
        React.createElement(View, { layer: SIZE.XL, position: POSITION.FIXED, style: styles(style.stack, screen.L && style.maxWidth, others.style) },
            React.createElement(SafeAreaView, null, Object.keys(state).map((key, index) => {
                const { component, props } = state[key];
                // eslint-disable-next-line react/prop-types
                const { onClose } = props;
                return React.createElement(component, Object.assign(Object.assign({}, props), { key, zIndex: index, onClose: () => {
                        value.hide(key);
                        onClose && onClose();
                    } }));
            }))),
        children));
};
StackProvider.displayName = 'AuroraStackProvider';
export { useStack, StackContext, StackProvider };
