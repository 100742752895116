import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
const AVAILABLE_VARIANTS = ['lining-nums', 'oldstyle-nums', 'proportional-nums', 'small-caps', 'tabular-nums'];
export const stylesheetFont = ({ nameSpace = '$fontBody', level = '' } = {}) => {
    const key = (attribute = '') => `${nameSpace}${attribute}${level}`;
    const fontName = StyleSheet.value(nameSpace);
    const fontSize = StyleSheet.value(key('Size'));
    const lineHeight = StyleSheet.value(key('Height'));
    const customFont = (StyleSheet.value('$fontMap') || {})[fontName];
    const customFontProps = !customFont
        ? {
            fontStyle: key('Style'),
            fontVariant: (StyleSheet.value(key('Variant')) || []).filter((variant) => AVAILABLE_VARIANTS.includes(variant)),
            fontWeight: key('Weight'),
        }
        : {};
    return StyleSheet.create({
        font: Object.assign({ fontFamily: customFont || fontName, fontSize: key('Size'), 
            // * https://github.com/facebook/react-native/issues/28012
            lineHeight: Platform.select({
                android: lineHeight >= fontSize ? lineHeight : fontSize,
                ios: undefined,
                web: lineHeight,
            }), letterSpacing: key('LetterSpacing'), paddingTop: key('PaddingTop'), paddingBottom: key('PaddingBottom') }, customFontProps),
    });
};
