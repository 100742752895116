import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    input: {
        paddingLeft: '$spaceXS',
        paddingRight: '$spaceXS',
    },
    length2: {
        width: () => StyleSheet.value('$fontInputSize * 2') + StyleSheet.value('$spaceXS * 2'),
    },
    length4: {
        width: () => StyleSheet.value('$fontInputSize * 4') + StyleSheet.value('$spaceXS * 2'),
    },
    firstInput: {
        width: () => StyleSheet.value('$fontInputSize * 2') + StyleSheet.value('$spaceS * 2'),
        paddingLeft: '$spaceS',
    },
    lastInput: {
        paddingLeft: '$spaceS',
        minWidth: '100%',
    },
});
