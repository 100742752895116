import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    containerShadow: {
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        borderColor: '$colorAccent',
    },
    contentShadow: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        borderColor: '$colorBase',
    },
});
