import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    container: {
        overflow: 'visible',
        padding: '$spaceS',
    },
    small: {
        padding: 0,
    },
    notification: {
        top: '$spaceXXS * -1',
        right: '$spaceXXS * -1',
        borderWidth: '$borderSize',
        borderStyle: '$borderStyle',
        borderRadius: () => StyleSheet.value('$spaceS + $borderSize') / 2,
        width: '$spaceS + $borderSize',
        height: '$spaceS + $borderSize',
    },
});
