var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { Platform, StatusBar } from 'react-native';
import { Box, ButtonIcon, Layout, Overlay } from '../../atoms';
import { testID } from '../../../helpers';
import { ALIGN, COLOR, FLEX_DIRECTION, POINTER, POSITION, SIZE, styles, useBack, useDevice, useSwipe } from '../../../hooks';
import { Motion, MOTION_TIMING, MOTION_TYPES, SafeAreaView, ScrollView, Text, TEXT_ROLES, View, VIEW_ROLES, } from '../../primitives';
import { Theme } from '../../../theming';
import { style } from './Modal.style';
const MIN_DELTA_TO_CLOSE = 80;
const Modal = React.forwardRef((_a, ref) => {
    var { children, color = COLOR.BASE, contentStyle, description, fullHeight = false, headerStyle, isVisible = false, overlayClose = true, nativeID, scrollToEnd = false, scrollable = false, size = { M: '2/3', L: '1/3' }, swipeable = false, title, onClose, onHardwareBackPress } = _a, others = __rest(_a, ["children", "color", "contentStyle", "description", "fullHeight", "headerStyle", "isVisible", "overlayClose", "nativeID", "scrollToEnd", "scrollable", "size", "swipeable", "title", "onClose", "onHardwareBackPress"]);
    const { screen } = useDevice();
    const handleSwipe = useSwipe({
        onSwiping: ({ deltaY, down }) => {
            const nextTop = Math.abs(parseInt(`${deltaY}`, 10));
            if (!down || nextTop === 0)
                return;
            setTop(nextTop);
            setSwiping(true);
        },
        onSwiped: ({ deltaY, down }) => {
            if (!down)
                return;
            setTop(0);
            if (onClose && Math.abs(deltaY) >= MIN_DELTA_TO_CLOSE)
                onClose();
            else
                setTimeout(() => setSwiping(false), motionCollapse);
        },
    });
    useBack(useCallback(() => {
        if (isVisible) {
            if (onHardwareBackPress)
                onHardwareBackPress();
            else if (onClose)
                onClose();
        }
        return isVisible;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible, onClose]));
    const { motionCollapse, spaceXXL } = Theme.get();
    const [top, setTop] = useState(0);
    const [swiping, setSwiping] = useState(false);
    useEffect(() => {
        setSwiping(false);
        if (isVisible)
            setTop(0);
    }, [isVisible]);
    const colorContent = color === COLOR.CONTENT ? COLOR.BASE : undefined;
    const isSwipeable = screen.S && swipeable;
    const SafeArea = fullHeight ? SafeAreaView : View;
    const Scroller = scrollable ? ScrollView : React.Fragment;
    const maxHeight = Platform.OS === 'web' ? { maxHeight: screen.height - (!screen.S ? spaceXXL * 2 : 0) } : {};
    return (React.createElement(View, Object.assign({}, others, (!isVisible
        ? {
            accessibilityElementsHidden: true,
            importantForAccessibility: 'no-hide-descendants',
            pointer: POINTER.NONE,
        }
        : undefined), { alignItems: screen.S ? ALIGN.END : ALIGN.CENTER, flexDirection: FLEX_DIRECTION.ROW, layer: SIZE.L, nativeID: nativeID ? `${nativeID}-container` : undefined, position: POSITION.FIXED, role: VIEW_ROLES.aside, style: styles(style.modal, others.style), wide: true }),
        React.createElement(Overlay, { isVisible: isVisible, nativeID: nativeID ? `${nativeID}-overlay` : undefined, onPress: onClose && overlayClose ? onClose : undefined }),
        React.createElement(Layout, { fullWidth: screen.S, justifyContent: ALIGN.CENTER, layer: SIZE.L, nativeID: nativeID ? `${nativeID}` : undefined, style: [maxHeight, fullHeight && style.fullHeight] },
            React.createElement(Motion, { disabled: top !== 0, layout: screen, position: POSITION.RELATIVE, role: VIEW_ROLES.section, style: fullHeight ? style.fullHeight : undefined, timing: isVisible && ((isVisible && screen.S && swiping) || !screen.S) ? MOTION_TIMING.SPRING : undefined, type: isVisible ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE, value: screen.S
                    ? { translateY: isVisible ? top || '0%' : '100%' }
                    : { opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.9 }, wide: true },
                React.createElement(Box, { align: ALIGN.CENTER, backgroundColor: color, paddingTop: onClose ? SIZE.S : SIZE.XXXL, size: size, style: [style.box, fullHeight && style.fullHeight] },
                    React.createElement(SafeArea, { style: [{ maxHeight: '100%', paddingBottom: 8 }, fullHeight && { flex: 1 }], wide: true },
                        fullHeight && Platform.OS === 'android' && React.createElement(StatusBar, { translucent: false }),
                        onClose && (React.createElement(View, Object.assign({}, (isSwipeable && isVisible ? handleSwipe : undefined), { flexDirection: FLEX_DIRECTION.ROW, justifyContent: isSwipeable ? ALIGN.CENTER : ALIGN.END, paddingRight: !isSwipeable ? SIZE.L : undefined, layer: SIZE.S, wide: !isSwipeable, style: headerStyle }),
                            React.createElement(ButtonIcon, { backgroundColor: color, color: colorContent, name: isSwipeable ? 'arrow-down' : 'close', accessibilityLabel: "close", style: style.iconClose, onPress: onClose, testID: nativeID ? `${nativeID}-close` : undefined }))),
                        React.createElement(Scroller, Object.assign({}, (scrollable ? { scrollToEnd } : {})),
                            React.createElement(View, { ref: ref, style: [style.content, contentStyle, fullHeight && style.fullHeight] },
                                (title || description) && (React.createElement(View, { alignItems: ALIGN.CENTER, role: "header", flexDirection: FLEX_DIRECTION.COLUMN, marginTop: onClose && (title || description) ? SIZE.XL : undefined, marginBottom: SIZE.L },
                                    title && (React.createElement(Text, Object.assign({ align: ALIGN.CENTER, color: colorContent, level: 1, role: TEXT_ROLES.h2 }, testID(others.testID, 'title')), title)),
                                    description && (React.createElement(Text, { align: ALIGN.CENTER, color: colorContent, level: 2, marginTop: title ? SIZE.M : undefined }, description)))),
                                children))))))));
});
Modal.displayName = 'Modal';
export { Modal };
