import { Platform, Easing } from 'react-native';
export const DefaultTheme = {
    $theme: 'aurora',
    /* BORDER */
    $borderRadius: 0,
    $borderSize: 1,
    $borderStyle: 'solid',
    /* COLOR */
    $colorBase: 'white',
    $colorContent: 'black',
    $colorAccent: '#ffd9c7',
    $colorPrimary: '#ff9081',
    $colorAlert: '#ffdb7c',
    $colorInfo: '#f2e8d9',
    $colorGrayscaleXL: '#7a7a7a',
    $colorGrayscaleL: '#ababab',
    $colorGrayscaleM: '#cfcfcf',
    $colorGrayscaleS: '#e7e7e7',
    $colorOverlay: 'rgba(231, 231, 231, 0.8)',
    $colorTouchable: 'rgba(255, 255, 255, 0.15)',
    /* ELEVATION */
    $elevationOffset: { width: 0, height: 8 },
    $elevationRadius: 18,
    $elevationOpacityS: 0.075,
    $elevationOpacityM: 0.15,
    $elevationColor: 'rgb(0, 0, 0)',
    $elevationLayerXS: 1,
    $elevationLayerS: 2,
    $elevationLayerM: 3,
    $elevationLayerL: 4,
    $elevationLayerXL: 5,
    /* FONT */
    $fontMap: {
        Brand: 'Peacelove-Medium',
        Heading: 'Romie-Regular',
        Body: 'Canela-Light',
        Action: 'FoundersGroteskText-Regular',
        Detail: 'FoundersGroteskText-Regular',
    },
    $fontBrand: 'Brand',
    $fontBrandStyle1: 'normal',
    $fontBrandVariant1: ['normal'],
    $fontBrandWeight1: '500',
    $fontBrandSize1: 40,
    $fontBrandHeight1: 48,
    $fontBrandPaddingTop1: 0,
    $fontBrandPaddingRight1: 0,
    $fontBrandPaddingBottom1: 0,
    $fontBrandPaddingLeft1: 0,
    $fontBrandLetterSpacing1: 0,
    $fontHeading: 'Heading',
    $fontHeadingStyle1: 'normal',
    $fontHeadingVariant1: ['normal'],
    $fontHeadingWeight1: 'normal',
    $fontHeadingSize1: 101,
    $fontHeadingHeight1: 88,
    $fontHeadingPaddingTop1: 0,
    $fontHeadingPaddingRight1: 0,
    $fontHeadingPaddingBottom1: 0,
    $fontHeadingPaddingLeft1: 0,
    $fontHeadingLetterSpacing1: -4,
    $fontHeadingStyle2: 'normal',
    $fontHeadingVariant2: ['normal'],
    $fontHeadingWeight2: 'normal',
    $fontHeadingSize2: 70,
    $fontHeadingHeight2: 61,
    $fontHeadingPaddingTop2: 0,
    $fontHeadingPaddingRight2: 0,
    $fontHeadingPaddingBottom2: 0,
    $fontHeadingPaddingLeft2: 0,
    $fontHeadingLetterSpacing2: -2,
    $fontHeadingStyle3: 'normal',
    $fontHeadingVariant3: ['normal'],
    $fontHeadingWeight3: 'normal',
    $fontHeadingSize3: 45,
    $fontHeadingHeight3: 48,
    $fontHeadingPaddingTop3: 0,
    $fontHeadingPaddingRight3: 0,
    $fontHeadingPaddingBottom3: 0,
    $fontHeadingPaddingLeft3: 0,
    $fontHeadingLetterSpacing3: -1.5,
    $fontBody: 'Body',
    $fontBodyStyle1: 'normal',
    $fontBodyVariant1: ['normal'],
    $fontBodyWeight1: '300',
    $fontBodySize1: 34,
    $fontBodyHeight1: 36,
    $fontBodyPaddingTop1: 0,
    $fontBodyPaddingRight1: 0,
    $fontBodyPaddingBottom1: 0,
    $fontBodyPaddingLeft1: 0,
    $fontBodyLetterSpacing1: 0.5,
    $fontBodyStyle2: 'normal',
    $fontBodyVariant2: ['normal'],
    $fontBodyWeight2: '300',
    $fontBodySize2: 22,
    $fontBodyHeight2: 25,
    $fontBodyPaddingTop2: 2,
    $fontBodyPaddingRight2: 0,
    $fontBodyPaddingBottom2: 0,
    $fontBodyPaddingLeft2: 0,
    $fontBodyLetterSpacing2: 0.3,
    $fontBodyStyle3: 'normal',
    $fontBodyVariant3: ['normal'],
    $fontBodyWeight3: '300',
    $fontBodySize3: 18,
    $fontBodyHeight3: 21,
    $fontBodyPaddingTop3: 0,
    $fontBodyPaddingRight3: 0,
    $fontBodyPaddingBottom3: 0,
    $fontBodyPaddingLeft3: 0,
    $fontBodyLetterSpacing3: 0.5,
    $fontAction: 'Action',
    $fontActionStyle1: 'normal',
    $fontActionVariant1: ['normal'],
    $fontActionWeight1: 'normal',
    $fontActionSize1: 40,
    $fontActionHeight1: 40,
    $fontActionPaddingTop1: 0,
    $fontActionPaddingRight1: 0,
    $fontActionPaddingBottom1: 0,
    $fontActionPaddingLeft1: 0,
    $fontActionLetterSpacing1: 2.8,
    $fontActionStyle2: 'normal',
    $fontActionVariant2: ['normal'],
    $fontActionWeight2: 'normal',
    $fontActionSize2: 22,
    $fontActionHeight2: 24,
    $fontActionPaddingTop2: 0,
    $fontActionPaddingRight2: 0,
    $fontActionPaddingBottom2: 0,
    $fontActionPaddingLeft2: 0,
    $fontActionLetterSpacing2: 2.8,
    $fontActionStyle3: 'normal',
    $fontActionVariant3: ['normal'],
    $fontActionWeight3: 'normal',
    $fontActionSize3: 13,
    $fontActionHeight3: 16,
    $fontActionPaddingTop3: 0,
    $fontActionPaddingRight3: 0,
    $fontActionPaddingBottom3: 0,
    $fontActionPaddingLeft3: 0,
    $fontActionLetterSpacing3: 2.8,
    $fontDetail: 'Detail',
    $fontDetailStyle1: 'normal',
    $fontDetailVariant1: ['normal'],
    $fontDetailWeight1: 'normal',
    $fontDetailSize1: 16,
    $fontDetailHeight1: 18,
    $fontDetailPaddingTop1: 1,
    $fontDetailPaddingRight1: 0,
    $fontDetailPaddingBottom1: 0,
    $fontDetailPaddingLeft1: 0,
    $fontDetailLetterSpacing1: 0,
    $fontDetailStyle2: 'normal',
    $fontDetailVariant2: ['normal'],
    $fontDetailWeight2: 'normal',
    $fontDetailSize2: 13,
    $fontDetailHeight2: 16,
    $fontDetailPaddingTop2: 1,
    $fontDetailPaddingRight2: 0,
    $fontDetailPaddingBottom2: 0,
    $fontDetailPaddingLeft2: 0,
    $fontDetailLetterSpacing2: 0,
    $fontDetailStyle3: 'normal',
    $fontDetailVariant3: ['normal'],
    $fontDetailWeight3: 'normal',
    $fontDetailSize3: 11,
    $fontDetailHeight3: 16,
    $fontDetailPaddingTop3: 0,
    $fontDetailPaddingRight3: 0,
    $fontDetailPaddingBottom3: 0,
    $fontDetailPaddingLeft3: 0,
    $fontDetailLetterSpacing3: 1.4,
    /* ICON */
    $iconFamily: 'auroraicons',
    $iconGlyphs: require('@lookiero/aurora-iconfont/dist/glyphs.json'),
    $iconSize: 24,
    /* INPUT */
    $fontInput: 'Body',
    $fontInputStyle: 'normal',
    $fontInputVariant: ['normal'],
    $fontInputWeight: '300',
    $fontInputSize: 18,
    $fontInputHeight: 20,
    $fontInputPaddingTop: Platform.OS === 'web' ? 4 : 1,
    $fontInputPaddingRight: 16,
    $fontInputPaddingBottom: 0,
    $fontInputPaddingLeft: 16,
    $fontInputLetterSpacing: 0.5,
    $inputOptionSize: 20,
    $inputSize: 56,
    $inputSizeSmall: 40,
    $inputSizeLarge: 72,
    /* LAYOUT */
    $layoutMaxWidth: 1280,
    /* MOTION */
    $motionCollapse: 250,
    $motionExpand: 300,
    $motionSkeleton: 1200,
    $motionSlow: 5000,
    $motionEasing: {
        // @ts-ignore
        linear: () => Easing.linear(),
        spring: () => Easing.bezier(0.47, 1.64, 0.41, 0.8),
        quad: () => Easing.inOut(Easing.quad),
    },
    /* SPACING */
    $spaceXXS: 2,
    $spaceXS: 4,
    $spaceS: 8,
    $spaceM: 16,
    $spaceL: 24,
    $spaceXL: 32,
    $spaceXXL: 40,
    $spaceXXXL: 48,
    /* LAYOUT */
    $layoutXXS: 16,
    $layoutXS: 24,
    $layoutS: 32,
    $layoutM: 48,
    $layoutL: 64,
    $layoutXL: 80,
    $layoutXXL: 96,
    $layoutXXXL: 120,
};
