import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    dartBoard: {
        top: 0,
        height: '$inputSize * 5',
    },
    selector: {
        borderBottomWidth: '$borderSize',
        borderTopWidth: '$borderSize',
        borderStyle: '$borderStyle',
        height: '$inputSize',
    },
    gradient: {
        height: '$inputSize * 2',
    },
});
