// TODO this should be removed and we should use uuid lib or something
import { useState } from 'react';
const { NODE_ENV } = process.env;
const UUID_SCAFFOLD = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
const UUID = () => {
    let timestamp = new Date().getTime();
    return NODE_ENV !== 'test'
        ? UUID_SCAFFOLD.replace(/[xy]/g, (char) => {
            const random = (timestamp + Math.random() * 16) % 16 | 0;
            timestamp = Math.floor(timestamp / 16);
            return (char === 'x' ? random : (random & 0x3) | 0x8).toString(16);
        })
        : UUID_SCAFFOLD;
};
const useId = (id) => {
    const [uuid] = useState(id || UUID());
    return uuid;
};
export { useId };
