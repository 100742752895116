import StyleSheet from 'react-native-extended-stylesheet';
const verticalStyle = {
    step: {
        height: '$spaceXXXL',
    },
    track: {
        width: '$spaceM',
        height: '$spaceM',
    },
    line: {
        borderRightWidth: '$borderSize',
        borderStyle: '$borderStyle',
        height: '$spaceM',
        left: '50%',
    },
    start: {
        top: '-100%',
        transform: () => [{ translateX: StyleSheet.value('$spaceXS') / 2 }, { translateY: -StyleSheet.value('$spaceXS') }],
    },
    end: {
        bottom: '-100%',
        transform: () => [{ translateX: StyleSheet.value('$spaceXS') / 2 }, { translateY: StyleSheet.value('$spaceXS') }],
    },
};
const horizontalStyle = {
    step: {
        flex: 1,
    },
    track: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    line: {
        top: () => (StyleSheet.value('$spaceM') + StyleSheet.value('$spaceXS')) / 2,
        borderTopWidth: '$borderSize',
        borderStyle: '$borderStyle',
        flex: 1,
    },
    start: {
        right: '50%',
        left: 0,
        transform: () => [{ translateX: -StyleSheet.value('$spaceM / 2') - StyleSheet.value('$spaceS') }],
    },
    end: {
        left: '50%',
        right: 0,
        transform: () => [{ translateX: +StyleSheet.value('$spaceM / 2') + StyleSheet.value('$spaceS') }],
    },
};
export const style = (horizontal) => StyleSheet.create(Object.assign(Object.assign({}, (horizontal ? horizontalStyle : verticalStyle)), { circle: {
        borderWidth: '$borderSize',
        borderStyle: '$borderStyle',
        borderRadius: () => StyleSheet.value('$spaceM /2') + StyleSheet.value('$spaceXS / 2'),
        width: '$spaceM + $spaceXS',
        height: '$spaceM + $spaceXS',
    }, icon: {
        top: '$spaceXXS * -1',
        left: '$spaceXXS * -1',
        width: '$iconSize',
        height: '$iconSize',
    } }));
