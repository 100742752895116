import { IS_JEST } from '../../helpers';
const emptyFn = () => {
    if (!IS_JEST)
        // eslint-disable-next-line no-console
        console.warn("WARN: Can't use the hook usePortal without an instance of <PortalProvider>");
};
const DEFAULT_PORTAL = {
    busy: false,
    instance: false,
    mount: emptyFn,
    unmount: emptyFn,
};
export { DEFAULT_PORTAL };
