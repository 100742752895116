var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ButtonIcon } from '../../atoms';
import { getToday, UTC } from '../../../helpers';
import { COLOR, FLEX_DIRECTION, SIZE, ALIGN } from '../../../hooks';
import { Text, View } from '../../primitives';
import { DAYS, VISIBLE_WEEKS } from './Calendar.definition';
import { getFirstDateOfMonth, getHeader, getWeekDays, getWeekNumber } from './helpers';
import { Week } from './Week';
export const Calendar = (_a) => {
    var { disabledDates = [], disabledPast = false, disabledToday = false, from, locale, to, value, onChange } = _a, others = __rest(_a, ["disabledDates", "disabledPast", "disabledToday", "from", "locale", "to", "value", "onChange"]);
    const [instance, setInstance] = useState(getFirstDateOfMonth(getToday()));
    const [selected, setSelected] = useState(undefined);
    useEffect(() => {
        if (!value)
            return;
        setInstance(getFirstDateOfMonth(new Date(value)));
        setSelected(UTC(new Date(value)));
    }, [value]);
    const handleChange = (date) => {
        setSelected(date);
        if (onChange)
            onChange(date);
    };
    const handleMonth = (month) => setInstance(getFirstDateOfMonth(new Date(instance.getFullYear(), instance.getMonth() + month)));
    const instanceTS = instance.getTime();
    const todayMonthTS = getFirstDateOfMonth(getToday()).getTime();
    const disabledPrevious = (disabledPast && instanceTS <= todayMonthTS) ||
        (from ? instanceTS <= getFirstDateOfMonth(new Date(from)).getTime() : false);
    const disabledNext = to ? instanceTS >= getFirstDateOfMonth(new Date(to)).getTime() : false;
    const weekNumber = getWeekNumber(instance);
    const weekdays = getWeekDays(locale);
    return (React.createElement(View, Object.assign({}, others),
        React.createElement(View, { flexDirection: FLEX_DIRECTION.ROW, alignItems: ALIGN.CENTER, paddingHorizontal: SIZE.S, marginBottom: SIZE.L },
            React.createElement(ButtonIcon, { color: disabledPrevious ? COLOR.GRAYSCALE_M : undefined, disabled: disabledPrevious, name: "arrow-left", testID: "previous-month", small: true, onPress: () => handleMonth(-1) }),
            React.createElement(Text, { action: true, align: ALIGN.CENTER, flex: SIZE.XS, upperCase: true, marginHorizontal: SIZE.XS }, getHeader(instance, locale)),
            React.createElement(ButtonIcon, { color: disabledNext ? COLOR.GRAYSCALE_M : undefined, disabled: disabledNext, name: "arrow-right", testID: "next-month", small: true, onPress: () => handleMonth(1) })),
        React.createElement(View, { flexDirection: FLEX_DIRECTION.ROW, marginBottom: SIZE.S }, DAYS.map((day) => (React.createElement(Text, { action: true, align: ALIGN.CENTER, flex: SIZE.XS, key: day, paddingHorizontal: SIZE.XS, upperCase: true }, weekdays[day])))),
        VISIBLE_WEEKS.map((week) => (React.createElement(Week, Object.assign({}, others, { disabledDates: disabledDates, disabledPast: disabledPast, disabledToday: disabledToday, from: from, to: to, selected: selected, key: week, month: instance.getMonth(), number: weekNumber + week, year: instance.getFullYear(), onPress: handleChange }))))));
};
Calendar.displayName = 'Calendar';
