export const groupState = ({ initialValue = {}, value = {}, touched = {} } = {}) => {
    const state = {};
    Object.keys(initialValue).forEach((field) => {
        state[field] = {
            initialValue: initialValue[field],
            value: value[field],
            touched: touched[field] || false,
        };
    });
    return state;
};
