var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Picker } from '@react-native-picker/picker';
import React, { useImperativeHandle } from 'react';
import { Platform } from 'react-native';
import { stylesheetFont, testID } from '../../../helpers';
import { styles, useBanStylerProps, useDevice, useStyler } from '../../../hooks';
import { Theme } from '../../../theming';
import { style } from './Select.style';
import { SelectIOS } from './SelectIOS';
const Select = React.forwardRef(
// eslint-disable-next-line react/prop-types
(_a, ref) => {
    var { disabled, emptyOptionText: emptyOption, id, options = [], value, onChange } = _a, others = __rest(_a, ["disabled", "emptyOptionText", "id", "options", "value", "onChange"]);
    const handleChange = (next) => {
        const nextValue = next === emptyOption ? undefined : next;
        onChange && onChange(nextValue);
        others.onBlur && others.onBlur();
    };
    const styleFont = stylesheetFont({ nameSpace: '$fontInput' }).font;
    const baseColor = Theme.get('$colorBase');
    const children = [];
    if (emptyOption)
        children.push(React.createElement(Picker.Item, { key: emptyOption, label: emptyOption, value: emptyOption }));
    options.forEach((option = { name: '', value: '' }) => {
        const { name, value } = typeof option === 'object' ? option : { name: option, value: option };
        children.push(React.createElement(Picker.Item, { key: value, label: name, value: value }));
    });
    useImperativeHandle(ref, () => {
        return Platform.OS === 'ios'
            ? ref
            : {
                focus() {
                    // library is broken in version 1.x so at least do not throw an error in case of android or web
                },
                blur() {
                    // library is broken in version 1.x so at least do not throw an error in case of android or web yarn start
                },
            };
    });
    return React.createElement(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Platform.OS === 'ios' ? SelectIOS : Picker, Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, useBanStylerProps(others)), useStyler(Object.assign(Object.assign({}, others), { style: styles(styleFont, style.container, disabled && style.disabled, others.style) }), Select.displayName, useDevice())), testID(others.testID, 'input')), { id, enabled: !disabled, itemStyle: [styleFont, style.item], onValueChange: handleChange, ref: ref, selectedValue: value }), (Platform.OS === 'android'
        ? {
            dropdownIconColor: baseColor,
            dropdownIconRippleColor: baseColor,
        }
        : {})), children);
});
Select.displayName = 'Select';
export { Select };
