import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    item: {
        borderStyle: '$borderStyle',
        borderWidth: '$borderSize',
        justifyContent: 'center',
        minHeight: '$layoutL',
    },
    itemWithoutBorder: {
        borderColor: 'transparent',
    },
    content: {
        minWidth: 0,
    },
    detail: {
        marginLeft: '$spaceM',
        maxWidth: '$layoutXXL',
    },
});
