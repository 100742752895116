import { capitalize } from '../../helpers';
import { COORD, DOMAINS, DOMAIN_SHORTCUT } from './styler.definition';
const COORDS = [COORD.TOP, COORD.RIGHT, COORD.BOTTOM, COORD.LEFT];
const SUFFIXES = {
    Horizontal: [COORD.RIGHT, COORD.LEFT],
    Vertical: [COORD.TOP, COORD.BOTTOM],
};
const flatten = (...props) => props.map((key) => key.trim()).join('');
const DEFAULT_BREAKPOINT = 'default';
export const styler = (props = {}, breakpoints = {}) => {
    const styles = [];
    const breakpoint = Object.keys(breakpoints)
        .filter((key) => breakpoints[key])
        .pop();
    Object.keys(DOMAINS).forEach((domain) => {
        let style = props[domain];
        if (style)
            domain = DOMAIN_SHORTCUT[domain] || domain;
        if (Array.isArray(style)) {
            style.forEach((size, coord) => {
                if (size)
                    styles.push(`${domain}${capitalize(COORDS[coord])}${capitalize(size)}`);
            });
        }
        else {
            if (typeof style === 'object')
                style = style[breakpoint] || style[DEFAULT_BREAKPOINT];
            if (typeof style === 'boolean')
                style = style ? ' ' : undefined;
            if (style) {
                style = capitalize(style);
                const styled = Object.keys(SUFFIXES).some((suffix) => {
                    const [baseDomain, ...rest] = domain.split(suffix);
                    const found = rest.length > 0;
                    if (found)
                        SUFFIXES[suffix].forEach((coord) => styles.push(flatten(baseDomain, coord, style)));
                    return found;
                });
                if (!styled && typeof style !== 'object')
                    styles.push(flatten(domain, style));
            }
        }
    });
    return styles;
};
