import { useState, useEffect } from 'react';
import { hasValue } from './hasValue';
export const useField = ({ defaultValue, value, onChange } = {}) => {
    const [filled, setFilled] = useState(() => hasValue(defaultValue));
    useEffect(() => {
        setFilled(hasValue(defaultValue));
    }, [defaultValue]);
    useEffect(() => {
        if (value || !defaultValue)
            setFilled(hasValue(value));
    }, [defaultValue, value]);
    const handleChange = (value) => {
        setFilled(hasValue(value));
        if (onChange)
            onChange(value);
    };
    return {
        filled,
        handleChange,
    };
};
