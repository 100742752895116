var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { Overlay } from '../../atoms';
import { ALIGN, COLOR, POINTER, POSITION, SIZE, styles, useBack, useBanStylerProps, useDevice, useSwipe } from '../../../hooks';
import { Motion, MOTION_TIMING, MOTION_TYPES, SafeAreaView, View, VIEW_ROLES } from '../../primitives';
import { style } from './Panel.style';
const DELAY_MS = 200;
export const Panel = React.forwardRef((_a, ref) => {
    var { children, color = COLOR.INFO, hasOverlay = false, isVisible = false, align = ALIGN.LEFT, onClose, onHardwareBackPress } = _a, others = __rest(_a, ["children", "color", "hasOverlay", "isVisible", "align", "onClose", "onHardwareBackPress"]);
    const { screen } = useDevice();
    const handleSwipe = useSwipe({
        onSwiping: ({ deltaX, left, right }) => {
            if ((!left && align === ALIGN.LEFT) || (!right && align === ALIGN.RIGHT))
                return;
            setLeft(parseInt(deltaX, 10));
            setSwiping(true);
        },
        onSwiped: ({ deltaX, left, right }) => {
            if ((!left && align === ALIGN.LEFT) || (!right && align === ALIGN.RIGHT))
                return;
            setLeft(0);
            if (onClose && Math.abs(deltaX) >= screen.width / 3)
                onClose();
            else
                setTimeout(() => setSwiping(false), DELAY_MS);
        },
    });
    useBack(useCallback(() => {
        if (isVisible) {
            if (onHardwareBackPress)
                onHardwareBackPress();
            else if (onClose)
                onClose();
        }
        return isVisible;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible, onClose]));
    const [left, setLeft] = useState(0);
    const [swiping, setSwiping] = useState(false);
    useEffect(() => {
        setSwiping(false);
        if (isVisible)
            setLeft(0);
    }, [isVisible]);
    return (React.createElement(View, Object.assign({}, (!isVisible
        ? {
            accessibilityElementsHidden: true,
            pointer: POINTER.NONE,
            importantForAccessibility: 'no-hide-descendants',
        }
        : undefined), { layer: SIZE.L, position: POSITION.FIXED, style: styles(style.panel, align === ALIGN.RIGHT && style.panelRight, hasOverlay ? (align === ALIGN.RIGHT ? style.overlayRight : style.overlay) : undefined, others.style), role: VIEW_ROLES.aside, wide: true }),
        hasOverlay && React.createElement(Overlay, { isVisible: isVisible, onPress: onClose }),
        React.createElement(Motion, Object.assign({}, useBanStylerProps(others), { backgroundColor: color, disabled: left !== 0, layer: SIZE.L, position: POSITION.RELATIVE, ref: ref, role: VIEW_ROLES.section, style: style.motion, timing: screen.S && swiping && isVisible ? MOTION_TIMING.SPRING : undefined, type: isVisible ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE, value: { translateX: isVisible ? left || '0%' : `${align === ALIGN.LEFT ? '-' : ''}100%` } }),
            screen.S && (React.createElement(View, Object.assign({}, (isVisible ? handleSwipe : undefined), { layer: SIZE.XS, position: POSITION.ABSOLUTE, style: styles(style.swiper, align === ALIGN.RIGHT && style.swiperRight) }))),
            React.createElement(SafeAreaView, { style: style.safeAreaView }, children))));
});
Panel.displayName = 'Panel';
