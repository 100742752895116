var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { ALIGN, COLOR, FLEX_DIRECTION, POINTER, SIZE } from '../../../hooks';
import { Icon, Text, Touchable, View, VIEW_ROLES } from '../../primitives';
import { style } from './Accordion.style';
const Accordion = React.forwardRef((_a, ref) => {
    var { active = false, children, disabled = false, text, title, onPress } = _a, others = __rest(_a, ["active", "children", "disabled", "text", "title", "onPress"]);
    return (React.createElement(View, Object.assign({}, others, { collapsable: false, role: VIEW_ROLES.article, ref: ref }),
        React.createElement(Touchable, { alignItems: ALIGN.CENTER, borderColor: COLOR.CONTENT, flexDirection: FLEX_DIRECTION.ROW, paddingVertical: text ? SIZE.M : SIZE.L, pointerEvents: disabled ? POINTER.NONE : undefined, style: style.touchable, tabIndex: 0, wide: true, onPress: !disabled && onPress ? () => onPress(!active) : undefined },
            React.createElement(View, { flexDirection: FLEX_DIRECTION.COLUMN, marginRight: SIZE.M, flex: SIZE.XS },
                React.createElement(Text, { action: true, color: disabled ? COLOR.GRAYSCALE_M : undefined, upperCase: true, wide: true }, title),
                !!text && (React.createElement(Text, { color: disabled ? COLOR.GRAYSCALE_M : COLOR.GRAYSCALE_XL, ellipsizeMode: true, marginTop: SIZE.S }, text))),
            React.createElement(Icon, { name: active ? 'collapse' : 'expand', color: disabled ? COLOR.GRAYSCALE_M : undefined })),
        active && React.createElement(View, { role: "section" }, children)));
});
Accordion.displayName = 'Accordion';
export { Accordion };
