var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Text } from 'react-native';
import { stylesheetColor, testID } from '../../../helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler } from '../../../hooks';
import { Theme } from '../../../theming';
import { BANNED_PROPS } from './Icon.definition';
import { style } from './Icon.style';
const Icon = (_a) => {
    var { color, name = 'lookiero' } = _a, others = __rest(_a, ["color", "name"]);
    return (React.createElement(Text, Object.assign({}, useBanProps(useBanStylerProps(others), BANNED_PROPS), useStyler(Object.assign(Object.assign({}, others), { style: styles(style.icon, stylesheetColor(color).color, others.style) }), Icon.displayName, useDevice()), testID(others.testID), { accessibilityElementsHidden: true, allowFontScaling: false, importantForAccessibility: "no", selectable: false }), String.fromCharCode((Theme.get('iconGlyphs') || {})[name])));
};
Icon.displayName = 'Icon';
export { Icon };
