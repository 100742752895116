const BANNED_PROPS = [
    'aria-controls',
    'aria-describedby',
    'aria-disabled',
    'aria-expanded',
    'aria-labelledby',
    'aria-modal',
    'aria-valuemax',
    'aria-valuemin',
    'aria-valuenow',
    'as',
    'disabled',
    'format',
    'formatValue',
    'htmlFor',
    'id',
    'onKeyUp',
    'style',
    'tabIndex',
];
var VIEW_ROLES;
(function (VIEW_ROLES) {
    VIEW_ROLES["article"] = "article";
    VIEW_ROLES["aside"] = "aside";
    VIEW_ROLES["button"] = "button";
    VIEW_ROLES["div"] = "div";
    VIEW_ROLES["figure"] = "figure";
    VIEW_ROLES["footer"] = "footer";
    VIEW_ROLES["form"] = "form";
    VIEW_ROLES["header"] = "header";
    VIEW_ROLES["li"] = "li";
    VIEW_ROLES["nav"] = "nav";
    VIEW_ROLES["ol"] = "ol";
    VIEW_ROLES["section"] = "section";
    VIEW_ROLES["table"] = "table";
    VIEW_ROLES["tbody"] = "tbody";
    VIEW_ROLES["td"] = "td";
    VIEW_ROLES["th"] = "th";
    VIEW_ROLES["thead"] = "thead";
    VIEW_ROLES["tr"] = "tr";
    VIEW_ROLES["ul"] = "ul";
})(VIEW_ROLES || (VIEW_ROLES = {}));
;
export { BANNED_PROPS, VIEW_ROLES };
