var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { Pressable } from 'react-native';
import { testID } from '../../../helpers';
import { DISPLAY, useBanProps, useBanStylerProps, useDevice, useStyler } from '../../../hooks';
import { pickDocument } from './helpers';
import { BANNED_PROPS } from './InputFile.definition';
import { InputFileModal } from './InputFileModal';
const InputFile = (_a) => {
    var { accept, children, disabled, forwardedRef, hidden = false, multiple = false, onChange, sourcesMessages } = _a, others = __rest(_a, ["accept", "children", "disabled", "forwardedRef", "hidden", "multiple", "onChange", "sourcesMessages"]);
    const { platform } = useDevice();
    const [modal, setModal] = useState(false);
    const handlePress = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (platform.web) {
            const doc = yield pickDocument(accept, multiple);
            if (doc && doc.file && onChange)
                onChange(doc.file);
        }
        else {
            setModal(true);
        }
    }), [accept, multiple, onChange]);
    const onFileSelect = (file) => {
        setModal(false);
        if (onChange)
            onChange(file);
    };
    useEffect(() => {
        if (forwardedRef) {
            forwardedRef.current = Object.assign(Object.assign({}, forwardedRef.current), { press: handlePress });
        }
    }, [forwardedRef, handlePress]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Pressable, Object.assign({}, useBanProps(useBanStylerProps(others), BANNED_PROPS), useStyler(Object.assign(Object.assign({}, others), { display: hidden ? DISPLAY.NONE : others.display }), InputFile.displayName, useDevice()), testID(others.testID), { onPress: !disabled && onChange ? handlePress : undefined }), children),
        modal ? (React.createElement(InputFileModal, { onFileSelect: onFileSelect, onClose: () => setModal(false), sourcesMessages: sourcesMessages })) : (React.createElement(React.Fragment, null))));
};
InputFile.displayName = 'InputFile';
export { InputFile };
