import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    input: {
        height: '$inputOptionSize',
        width: '$inputOptionSize',
    },
    icon: {
        top: '$spaceXXS * -1',
        left: '$spaceXXS * -1',
        width: '$iconSize',
        height: '$iconSize',
    },
});
