import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    container: Object.assign({ color: '$colorContent', borderWidth: 0, backgroundColor: 'transparent', height: '$inputSize', justifyContent: 'center', paddingTop: '$fontInputPaddingTop', paddingRight: '$fontInputPaddingRight', paddingBottom: '$fontInputPaddingBottom', paddingLeft: '$fontInputPaddingLeft', textAlignVertical: 'center', width: '100%' }, Platform.select({
        web: {
            appearance: 'none',
            outlineWidth: 0,
        },
    })),
    containerIOS: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        backgroundColor: '$colorGrayscaleS',
    },
    disabled: {
        color: '$colorGrayscaleM',
    },
    item: {
        color: '$colorContent',
    },
    itemIOS: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    pressableIconIOS: {
        alignItems: 'flex-end',
        alignContent: 'center',
        backgroundColor: '$colorTouchable',
        borderColor: '$colorGrayscaleS',
        borderStyle: '$borderStyle',
        borderTopWidth: '$borderSize',
        padding: '$spaceM',
    },
});
