var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { getInputErrors } from '../../../../../helpers';
import { ALIGN, FLEX_DIRECTION, styles } from '../../../../../hooks';
import { View } from '../../../../primitives';
import { Field } from './Field';
import { style } from './Fieldset.style';
const FieldSet = React.forwardRef((_a, ref) => {
    var { defaultValue = {}, fields, id, onChange, onError, value = {} } = _a, others = __rest(_a, ["defaultValue", "fields", "id", "onChange", "onError", "value"]);
    const [currentValue, setCurrentValue] = useState(Object.assign(Object.assign({}, defaultValue), value));
    const handleChange = (field, newValue) => {
        setCurrentValue(() => {
            const nextValue = Object.assign(Object.assign({}, currentValue), { [field]: newValue });
            if (onError) {
                const errors = getInputErrors(Object.assign(Object.assign({}, others), { value: nextValue }));
                onError(errors);
            }
            if (onChange)
                onChange(nextValue);
            return nextValue;
        });
    };
    const fieldKeys = Object.keys(fields);
    return (React.createElement(View, { alignItems: ALIGN.CENTER, flexDirection: FLEX_DIRECTION.ROW, style: styles(others.style) }, fieldKeys.map((field, index) => (React.createElement(Field, Object.assign({}, others, { accessibilityLabel: field, key: field, defaultValue: defaultValue[field] ? `${defaultValue[field]}` : undefined, id: `${id}-${field}`, label: field, last: fieldKeys.length === 1 || index === fieldKeys.length - 1, 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref: index === 0 ? ref : undefined, role: "spinbutton", style: style.field, testID: others.testID ? `${others.testID}-${field}` : undefined, type: fields[field], value: value[field], onChange: (newValue) => handleChange(field, newValue) }))))));
});
FieldSet.displayName = 'InputFieldFieldSet';
export { FieldSet };
